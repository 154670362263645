export const state = {
  nowMinuted: new Date
};

export const actions = {
  init ({ commit }) {
    setInterval(() => {
      commit('updateTime')
    }, 1000 * 60)
  }
};

export const mutations = {
  updateTime (state) {
    state.nowMinuted = new Date
  }
};
