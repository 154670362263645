<script>
const fcmI18n = require('@i18n/index');

let {languages} = fcmI18n;

require('typeface-montserrat');
require('typeface-roboto');
import {appConfig} from '@src/config';

export default {
  page() {
    return {
      // All subcomponent titles will be injected into this template.
      titleTemplate(title) {
        title = typeof title === 'function' ? title(this.$store) : title;
        return title ? `${title} | ${appConfig.title}` : appConfig.title;
      },
      meta: this.metaComputed,
      link: this.linkComputed,
    }
  },
  computed:{
    metaComputed() {
      let metaRet = [];
      metaRet.push({property: 'og:locale', content: this.$i18n.locale});
      for(let language in languages) {
        metaRet.push({property: 'og:locale:alternate', content: language});
      }
      return metaRet;
    },
    linkComputed() {
      let metaRet = [];
      if(this.$router.currentRoute) {
        metaRet.push({ rel: 'canonical', href: process.env.VUE_APP_BASE_URL_CANONICAL+this.$router.currentRoute.fullPath });
        for(let language in languages) {
          metaRet.push({ rel: 'alternate', href: process.env.VUE_APP_BASE_URL_CANONICAL+this.$router.currentRoute.fullPath+'?locale='+language, hreflang: language });
        }
      }
      return metaRet;
    }
  },
}


/*
Transição ao alterar rotas

import Velocity from 'velocity-animate'
methods: {

    beforeEnter: function(el) {
      el.style.opacity = 0;
    },

    enter: function(el) {
      Velocity(el, { opacity: 1 }, { duration: 800 });
    },

    leave: function(el) {
      Velocity(el, { opacity: 0 }, { duration: 800 });
    }

  }

<Transition
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            v-on:leave="leave"
            v-bind:css="false"
            mode="out-in"
    >

      <KeepAlive>
        <RouterView></RouterView>
      </KeepAlive>

    </Transition>

 */

</script>

<template>
  <VApp id="app">
    <!--
    Even when routes use the same component, treat them
    as distinct and create the component again.
    -->
    <!--<RouterView :key="$route.fullPath" />-->

    <RouterView></RouterView>

  </VApp>
</template>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style :src="require('@src/design/ck-editor-content-styles.css')"></style>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';
// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';


@import '~sweetalert2/src/variables';
// $swal2-background: #990000;
@import '~sweetalert2/src/sweetalert2';



// Design variables and utilities from src/design.
@import '@design';
// @import '~typeface-montserrat/index.css';

*,
*::before,
*::after {
  box-sizing: border-box;
}

#app {
  @extend %typography-small;
}

html {
  margin: 0 auto;
  height: 100%;
  padding: 0;
}
body {
  margin: 0 auto;
  //height: 100%!important;
  background-color: $color-body-bg;
  padding: 0;

  //.md-menu-content{
  //  min-width: 100%;
  //}
}

#app {
  /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
  /*font-family: Montserrat;*/

  @extend %font-content;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $color-text;
  height: 100%;
  padding: 0;
  background-color: unset;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: $color-text;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}


/*
$material-icons-codepoints: () !default; !* Sass map of icon names and codepoints *!
$material-icons-font-path: '' !default;
$material-icons-font-name: 'MaterialIcons-Regular' !default;
$material-icons-font-size: 24px !default;
$material-icons-font-family: 'Material Icons' !default;
*/


$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';

@import "~vue-material/dist/theme/engine"; // Import the theme engine

@include md-register-theme("default", (
        primary: $color-input-button-primary, // The primary color of your brand
        accent: #ffffff // The secondary color of your brand
));

@include md-register-theme("buttonprimary", (
        primary: $color-input-button-primary,
        accent: #ffffff
));

@include md-register-theme("white", (
  primary: #ffffff,
  accent: rgba(244, 15, 24, 0.21),
  theme: dark
));


@import "~vue-material/dist/theme/all";

.select-auto-width.md-menu-content.md-select-menu {
  width: auto!important;
  max-width: none;
}


.clear-float {
  clear: both;
}

.pointer {
  cursor: pointer;
}

// ===
// Base element styles
// ===

a,
a:visited {
  color: $color-link-text;
}

h1 {
  @extend %typography-xxlarge;
}

h2 {
  @extend %typography-xlarge;
}

h3 {
  @extend %typography-large;
}

h4 {
  @extend %typography-medium;
}

h5,
h6 {
  @extend %typography-small;
}



// ===
// Base form
// ===

.formItemSubSection {
  margin-left: 10px;
  margin-top: 30px;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.4;
  letter-spacing: 0.3px;
  color: #707070;
}

.formItem {
  position: relative;
  padding-left: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  /*box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);*/
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  .formItemImg {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .formItemDesc {
    flex: 10;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;


    -webkit-flex-direction: column;
    flex-direction: column;


    .formItemHead {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.87);
      padding-left: 8px;
      padding-right: 8px;
    }
    .formItemSub {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.54);
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .formItemAction {
    flex: 1;
    align-self: center;
    .md-radio, .md-switch {
      margin: 7px 16px 7px 0;
    }
    .formItemControl {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }

  .formItemAction .md-checked + .formItemControl  {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .formItemHelp {
    color: rgba(0, 0, 0, 0.12);
    align-self: center;
  }



}

.formItemFirst {
  /*box-shadow: inset 0 -1px 0 -1px rgba(0, 0, 0, 0.12);*/
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.formItemText {
  margin-left: 10px;
}


// Sobreescrever vue-material
.md-theme-default :not(input):not(textarea)::selection {
  background-color: #9AD2FF !important;
  color: #000000 !important;
}

.md-dialog-container {
  overflow: auto!important;
}

// ===
// Vendor
// ===

#nprogress .bar {
  background: $color-link-text;
}

//@font-face {
//  font-family: "MuseoSans";
//  src: url('/fonts/museo_sans/MuseoSans_300.otf');
//}

.multiselect__tag {
  background: $color-multiselect-tag-bg;
  color: $color-multiselect-tag-color;
}


</style>



