import firebase from 'firebase/app'
let configWeb = null;


export const state = {
  config: {}
};

export const actions = {
  init ({ commit }) {
    if(!configWeb) {
      configWeb = firebase.firestore().collection('config').doc('web').onSnapshot((snapshot) => {
        let value = {...snapshot.data(), id: snapshot.id};
        commit('updateConfig', value);
      });
    }
  },

};

export const mutations = {
  updateConfig (state, newValue) {
    state.config = newValue;
  }
};
