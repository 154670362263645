"use strict";

const _ = require('lodash');
let toExport = {};

// levenshtein alterado para substring, nesse algoritmo a ordem importa
toExport.fcmLevenshtein = function(source, target) {
  const result = []; // This is going to be a matrix. The result we're looking for can be found by flood-filling it.
  let i, j;

  // Let's fill the first line of the matrix with distances from each substring of the source to the empty string.
  for (i = 0; i <= source.length; i++) {
    result.push([i]);
  }

  // Same as above, but for a column. Also, the first element of the first column is already filled with a zero, hence j = 1.
  for (j = 1; j <= target.length; j++) {
    result[0].push(0);
  }

  for (i = 1; i <= source.length; i++) {
    for (j = 1; j <= target.length; j++) {

      // the element in the iteration doesn't exist yet. Let's create it.
      result[i].push(0);

      // Now let's get an edit distance between two characters.
      if (source[i-1] === target[j-1]) {

        // Notice how, for a comparison between two equal strings, the diagonal will be filled with zeroes.
        result[i][j] = result[i-1][j-1];
      } else {

        // Different characters. The formulae for edit distances are shown below.
        let minimum = Math.min(
          result[i-1][j] + 1, // This means we can bring the target string closer to the source one with a character deletion
          result[i][j-1] + 1  // Same as above, but with a character insertion
        );

        minimum = Math.min(
          minimum,
          result[i-1][j-1] + 1 // This means that we can bring the target closer to the source by means of a character change.
        );
        result[i][j] = minimum;
      }
    }
  }

  let fcmMin = target.length;
  for (j = 1; j <= target.length; j++) {
    if(fcmMin>result[source.length][j]) {
      fcmMin = result[source.length][j];
    }
  }
  return fcmMin;

  // The actual distance we're looking for is the value stored in the lower right corner of the matrix.
  // return result[source.length][target.length];
};



// É esperado as strings em lowercase e sem espaço no inicio e no fim
// let score = stringUtils.fcmlevenshteinNormalizeScore('start Buc', 'cafe starbucks - brasil');
// source.toLowerCase().trim();
toExport.fcmlevenshteinNormalizeScore = function(source, target) {
  if(!source) {return 0;}
  if(!target) {return 0;}
  let cont = 0;
  let scoreSearch = source.split(' ').reduce(function(o, term) {
    if(!term) {
      return o;
    }
    cont++;
    return o+(1-(toExport.fcmLevenshtein(term, target)/term.length));
  }, 0);
  scoreSearch /= cont || 1;
  return scoreSearch;
};


toExport.toTitleCase = (phrase) => {
  if(!phrase) {return '';}
  return phrase
  .toLowerCase()
  .split(' ')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ');
};


toExport.username = (phrase) => {
  if (!phrase) {
    return '';
  }
  phrase =_.deburr(phrase);

  return phrase.replace(/[^A-Za-z\d_-]/g, '');
};

toExport.deburrSlug = (txt) => {
  return (txt ||'').replace(/[^\dA-Za-z\-_]/g, '').trim();
};

toExport.shuffle = function (input) {
  let a = input.split(""),
    n = a.length;

  for(let i = n - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let tmp = a[i];
    a[i] = a[j];
    a[j] = tmp;
  }
  return a.join("");
};


module.exports = toExport;

// exports.default = exports;
// if(typeof module!=='undefined') {
//   module.exports = exports;
// }

