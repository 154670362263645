module.exports = {
  order: {
    title: "Order",
    order: "Order",
    date: "Date/Time",
    via: "Via",
    paymentTitle: "Payment",
    paymentOnTakeout: "Withdrawal Payment",
    clientName: "Name",
    clientPhone: "telephone",
    clientDocumentID: "CPF",
    product: "Product",
    qtd: "Qtd",
    viaOption: {
      "0": "Box / Counter",
      "1": "Internal / Kitchen",
      "2": "Deliveryman"
    },
    subTotal: "Subtotal",
    total: "Total",
    credits: "Credits",
    deliveryTax: "Delivery",
    changeFor: "Change for",
    change: "Change",
    address: "Address",
    paymentType: "Type of payment",
    type: "Type",
    deliveryAddress: "Address",
    deliveryType: "Type of order",
    value: "Value",
    itemName: "Product",
    itemQtd: "Amount",
    itemValueTotal: "Total",
    payment: "Payment",
    status: "State",
    time: "Date",
    newOrderNotificationTitle: "New request",
    newOrderNotificationBody: "A new request has just been received",
    changeForStatus: "Change to",
    number: "Request number",
    code: "Request code",
    numberSlim: "Number",
    codeSlim: "Code",
    distance: "Distance",
    itemObservation: "Note",
    obsOrder: "Note request",
    ingredientsRestrictions: "Restriction of ingredients"
  },
  apiErrorCode: {
    statusError: "Invalid state",
    permissionDenied: "The user is not allowed to perform this action",
    unknown: "Unknown error",
    paymentError: "Error making payment",
    bagError: "There is some mistake with the bag",
    inputError: "Parameter error",
    exhaustedAttempts: "Many attempts",
    paymentInvalidValue: "Invalid payment amount",
    notFound: "Not found",
    bagEmptyAddress: "Order without address",
    bagEmptyProduct: "Order empty",
    bagStatusError: "Order canceled or in an invalid state",
    iuguErrorCreateCompany: "Error creating account on iugu"
  },
  format: {
    data: "MM/DD/YYYY",
    dateTime: "DD / MM / YYYY HH: mm"
  },
  deliveryType: {
    delivery: "Delivery",
    pickup: "Withdraw in store",
    onSite: "On Request"
  },
  message: {
    hello: "hello world2"
  },
  login: {
    keepConnect: "Keep me signed in",
    emailAddress: "Email address",
    access: "Access Clinic",
    accessCompany: "Access Clinic",
    errorCompany: "No company associated with this email",
    login: "Log In",
    goReset: "I forgot my password",
    goLogin: "Return to login",
    accessClient: "Customer Access",
    accessAdmin: "Admin access",
    facebook: "Sign in with Facebook",
    google: "Sign in with Google",
    phone: "Login with Phone",
    apple: "Sign in with Apple",
    getPhoneNumber: "Enter your phone number",
    getPhoneCode: "Enter your verification code sent to your mobile phone",
    resetCompany: "Reset Password",
    reset: "Send email",
    error: {
      generic: "There was an error logging in",
      phoneEmpty: "Empty phone number",
      phoneCodeEmpty: "Empty verification code",
      loginTitle: "Error logging in",
      providerTitle: "Error adding provider"
    },
    providerCode: {
      "auth/invalid-email": "Invalid email",
      "auth/user-disabled": "Account disabled",
      "auth/user-not-found": "User not found",
      "auth/wrong-password": "Incorrect password",
      "auth/credential-already-in-use": "Credential already used",
      "auth/too-many-requests": "Too many attempts, try again later",
      "auth/operation-not-allowed": "Login type not configured, try another form of login"
    },
    addFacebook: "Add Facebook",
    addGoogle: "Add Google",
    addApple: "Add Apple",
    providers: "Authentication methods",
    remove: "To remove",
    providerId: "provider",
    email: "email",
    uid: "uid",
    changePassword: "Change Password",
    addPassword: "Add Password",
    resetClient: "Reset password",
    registerClient: "Register",
    register: "Register",
    googleRegister: "Register with Google",
    facebookRegister: "Register with Facebook",
    emailRegister: "Register with email",
    appleRegister: "Register with Apple",
    phoneRegister: "Registration with Telephone",
    resetPassword: "Reset password",
    makeRegister: "Register",
    accessOng: "Access NGO"
  },
  all: {
    font: "Font: ",
    noFont: "Font unavailable",
    selected: "Selected",
    all: "All",
    othersCategories: "Others",
    others: "Others",
    adminUsers: "Admin Users",
    anamnesis: "Anamnesis",
    clinicalStaff: "Clinical Staff",
    userDeleteExistAlready: "There is a deleted user associated with this email, an email for reset and reactivation has been sent to him.",
    teleconsultation: "Teleconsultation",
    enablePlaylistToCompany: "You need to associate at least one playlist with this clinic before activating it",
    understood: "Understand",
    accessDenied: "You do not have access to this area",
    financialReport: "Financial report",
    viewed: " Viewed",
    activeDesactive: "Enable / Disable",
    color: "Color",
    yes: "Yes",
    no: "No",
    professional: "Professional",
    newPatient: "New patient",
    patientNew: "New Patient",
    startOfConsultation: "Start of Consultation",
    endOfConsultation: "End of Consultation",
    statusWaitingRoom: {
      canceledByPatient: "Canceled by Patient",
      canceledByDoctor: "Canceled by Doctor",
      inConsultation: "In consultation",
      insideWaitingRoom: "Awaiting",
      normal: "Scheduled"
    },
    selectCareTypeIdRequired: "Select Service Type",
    selectTelemedicine: "Modality",
    selectMedicalAgreements: "Health insurance",
    telemedicine: "Teleconsultation",
    presential: "Presential",
    closed: "Closed",
    day: "Day",
    week: "Week",
    payment: {
      paymentByApp: "Payment by App",
      menuPayment: "Payment",
      dateOfPayment: "Pay day",
      dateOfPaymentBegin: "Payment Home",
      dateOfPaymentEnd: "Payment Order"
    },
    consultationDateBegin: "Inquiry Home",
    consultationDateEnd: "Consultation End",
    posts: "Posts",
    contentMixes: "Content mixes",
    editorials: "Editorials",
    recentsPublications: "Recent posts",
    choseCompaniesHint: "Choose the clinics where this playlist will be available.",
    choseCompanies: "Choose clinics",
    choseVideos: "Choose the videos to compose the playlist",
    comments: "Comments:",
    lunchTimeConflict: "It is not possible to schedule an appointment during lunch hours!",
    officeHoursConflict: "It is not possible to schedule an appointment outside office hours!",
    blockHoursConflict: "This time is blocked!",
    dateConflict: "It is not possible to schedule an appointment on this date!",
    selectPatient: "Select a Patient",
    selectSchedule: "Select an Agenda",
    noDoctors: "Without doctors",
    schedules: {
      schedule: "Schedule",
      schedules: "Schedules",
      appointment: "Schedule appointment",
      agend: "Schedule",
      delete: "Delete Schedule",
      new: "New schedule",
      saveSchedule: "Save Schedule",
      startConsultation: "Start service"
    },
    waiting: "WAITING FOR",
    waitingRoom: "Waiting room",
    items: "Items",
    enable: "Enable",
    disabled: "Disable",
    itemsByPage: "Items Per Page",
    noDataText: "No items registered",
    noDataResults: "No items found by search",
    noResultsText: "No items found by search",
    loadingItems: "Loading items ...",
    loadingPatients: "Loading patients ...",
    noPatients: "No patients listed",
    back: "Back",
    consultations: "consults ",
    payments: "Payments",
    value: "Price R$",
    physiotherapist: "Physiotherapist",
    doctor: "Doctor",
    nutritionist: "Nutritionist",
    psychologist: "Psychologist",
    type: "Type",
    date: "Date",
    typeService: "Service Type",
    duration: "Duration",
    durationInMinutes: "Duration (min)",
    disable: "DISABLED",
    confirm: "CONFIRM",
    disableModal: "Do you want to delete?",
    disableModalMessage: "Upon confirmation, this item will be deleted from the system.",
    actions: "Actions",
    invalidCPF: "Invalid CPF!",
    single: "Not married",
    married: "Married",
    separate: "Separate",
    divorced: "Divorced",
    widower: "Widower",
    female: "F",
    male: "M",
    careTypes: "Types of care",
    careType: "Type Attendance",
    typeCompany: "Clinic Profile",
    medicalAgreements: "Covenants",
    medicalAgreement: "Health insurance",
    patients: "Patients",
    patient: "Patient",
    playlists: "Playlists",
    videos: "Videos",
    restaurants: "Clinics",
    restaurant: "Clinic",
    next: "Next",
    knowMore: "Learn More",
    notImplemented: "Not implemented",
    attendance: "Attendance",
    orders: "Orders",
    reviews: "Reviews",
    promotions: "Promotions",
    settings: "Settings",
    products: "Products",
    delivery: "Delivery",
    schedule: "Schedule",
    information: "Information",
    informations: "Information",
    performance: "Performance",
    history: "History",
    demographics: "Demographics",
    of: "of",
    step: "Stage",
    category: "Category",
    profile: "Profile",
    categories: "Categories",
    users: "Users",
    paymentOptions: "Payment",
    otherOptions: "Others",
    conclude: "Conclude",
    close: "Close",
    cancel: "Cancel",
    nothingToList: "Empty List",
    search: "Search",
    productsCategories: "Categories",
    productsSubCategories: "Sub Categories",
    additionals: "Additional",
    createProduct: "Create Product",
    editCategory: "Edit Category",
    editProduct: "Edit Product",
    editSubCategory: "Edit Subcategory",
    settingsProducts: "Menu Settings",
    companiesTags: "Tags Company",
    productsNutritional: "Nutritional",
    productsNags: "Product Tags",
    vdTotal: "VD total",
    categoriesProducts: "Product Categories",
    productsTags: "Product Tags",
    productsIngredients: "Ingredients",
    cards: "Cards",
    companyConfigs: "Settings",
    edit: "To edit",
    create: "New",
    clients: "Customers",
    regions: "Regions",
    region: "Region",
    regionUtcOffset: "UtcOffset",
    jobs: "Jobs",
    tags: "Tags",
    tagAssociate: "Associated tag",
    exams: "My Exams",
    examsClients: "Customer exams",
    prescriptions: "My prescriptions",
    prescriptionsClients: "Customer requirements",
    blog: "Blog",
    blogVideos: "Blog Videos",
    faq: "FAQ",
    pages: "Pages",
    blogCategories: "Blog Categories",
    insurances: "Covenants",
    medicament_upload: "Upload drug list",
    exams_template: "Exam templates",
    changeImage: "Change image",
    healthDataFields: "Health data type",
    units: "Unit of measurement",
    isPreUser: "Pre registration",
    specialities: "Specialties",
    clinics: "Clinics",
    clinic: "Clinic",
    videoCategories: "Video categories",
    company_tags: "Tags Company",
    product_nutritional: "Nutritional",
    product_tags: "Tags Product",
    about: "About",
    forDoctors: "For doctors",
    help: "Help",
    subscriptions: "Subscriptions",
    resources: "Resources",
    android: "Android App",
    ios: "IOS App",
    press: "Press",
    recent: "Recent news",
    status: "Service status",
    ask: "Common questions",
    support: "Support",
    allRight: "All rights reserved © 2020",
    examCategories: "Exam categories",
    leftFooter: "More Health Teleconsultation - 2020",
    centerFooter: "More Health Teleconsultation",
    textLeftFooter: "All rights reserved ©",
    frstTxt: "More health",
    scndTxt: "SYSTEM ADMINISTRATOR",
    Admin: "ADMINISTRATOR",
    doctors: "Doctors",
    desktop: "Local Computer",
    cloud: "A cloud",
    videoNotReady: "Video preview not yet available"
  },
  support: {
    contact: "Please contact us at the phones and email addresses listed below.",
    email: "suporte@teleconsultamaissaude.com.br",
    emailContact: "contato@teleconsultamaissaude.com.br",
    phone: "(67) 3306-3337",
    phoneTxt: "Telephone:",
    comercial: "Commercial and collections",
    helpApp: "Help with application",
    complaints: "Complaints and compliments"
  },
  form: {
    image: "Image",
    availableForIugu: "Enable iugu account creation",
    daysBlockedInvalid: "Invalid Block Date!",
    hourServiceInvalid: "Invalid Service Hours!",
    hourBlockedInvalid: "Invalid Blocking Schedule!",
    idVideo: "Id Video",
    customLock: "Add Lock",
    customLocks: "Custom Locks",
    searchPatients: "Search Patients",
    hour: "Hour",
    dateConsult: "Date of consultation",
    doctor: "Doctor",
    week: {
      firstLetterSunday: "D",
      firstLetterMonday: "S",
      firstLetterTuesday: "T",
      firstLetterWednesday: "Q",
      firstLetterThursday: "Q",
      firstLetterFriday: "S",
      firstLetterSaturday: "S"
    },
    waitTime: "Waiting time",
    serviceHour: "Opening hours",
    blockingHours: "Blocking time",
    lunchHour: "Lunch time",
    until: "up until",
    averageServiceTime: "Average service time (minutes)",
    serviceDays: "Service Days",
    blockingDays: "Blocking Days",
    blockingWeekDays: "Blocking Days (in the week)",
    medicalAgreement: "Health insurance",
    birthday: "Date of birth",
    gender: "Gender",
    rg: "RG",
    civilStatus: "Marital status",
    profession: "Profession",
    religion: "Religion",
    cardNumber: "Card No.",
    zipCode: "Zip code",
    neighborhood: "Neighborhood",
    select: "Select",
    colorTag: "Service label color:",
    colorCategory: "Category label color:",
    standardTime: "Standard time in minutes for assistance",
    standardValue: "Standard value (R $) for service",
    dropZoneTitle: "Drag and drop to upload content!",
    dropZoneSubTitle: "...or click to select a file from your computer",
    error: {
      accessRequired: "You do not have the required access for this action",
      requiredShort: "Required",
      required: "This field is required",
      minLength: "The field is too short",
      maxLength: "The field is too big",
      update: "There was some error updating the data",
      email: "Invalid email",
      conflictEmail: "This email is already in use for another user",
      image: {
        type: "Tipo de arquivo inválido, esperado: PNG, JPEG",
        upload: "Erro ao fazer ulpload da imagem, tente novamente mais tarde!"
      },
      pdf: {
        type: "Invalid, expected file type: PDF",
        upload: "Error uploading PDF, try again later!"
      },
      invalidCPF: "Invalid cpf",
      formInvalid: "Invalid field",
      cpfError: "CPF;",
      emailError: "Email;",
      nameError: "Name;",
      phoneError: "Telephone;",
      crmError: "CRM;",
      specialtyError: "Specialty;",
      passwordError: "Password;",
      permissionError: "Permissions;",
      schedulesError: "Agendas;",
      cnpjError: "CNPJ;",
      corporateNameError: "Clinic name;",
      companyNameError: "Clinical name of the clinic;",
      slugError: "Keywords;",
      idError: "Id;",
      categoryError: "Category;",
      titleError: "Title;",
      fontError: "Fonte",
      abstractError: "Abstract;",
      clinicsError: "Clinics;",
      videosError: "Videos;",
      uploadImage: "Error while uploading image",
      closeEditor: "Please complete or cancel the editor before proceeding",
      showOnMenu: "Show in menu",
      regionAreaName: "Error fetching region area name",
      schedule: "Error reducing number of scales",
      tagType: "The tag must be applicable to products and or companies",
      onUpdate: "Wait for the previous operation to complete",
      chosePermissionAndSchedules: "Choose permissions and calendars"
    },
    update: "Update",
    companyName: "Name of clinic",
    corporateName: "Corporate name",
    updateSuccess: "Saved data",
    ownerName: "Owner name",
    email: "Email",
    password: "Password",
    continue: "Continue",
    cnpj: "CNPJ",
    cpf: "CPF",
    name: "Name",
    prevStep: "Previous step",
    nextStep: "Next step",
    conclude: "Conclude",
    close: "Close",
    cancel: "Cancel",
    changePrimaryColor: "Change primary color",
    changeSecondaryColor: "Change secondary color",
    emailResetSuccess: "In a moment you will receive an email to reset your password",
    emailResetError: "There was an error trying to send password reset email",
    changePassword: "Change Password",
    passwordNew: "New password",
    passwordOld: "Current Password",
    info: "Information of the clinic",
    address: {
      city: "City",
      complement: "Complement",
      country: "Country",
      district: "District",
      neighborhood: "Neighborhood",
      number: "Number",
      search: "Search",
      state: "State",
      street: "Street",
      emptyResult: "No address found",
      fieldWarning: "Enter the address correctly",
      zipcode: "Zip code"
    },
    contact: "Contact",
    description: "Description",
    phone: "Telephone",
    phone1: "Phone 1",
    phone2: "Phone 2",
    delete: "Delete",
    edit: "Edit",
    add: "Add",
    permission: "Permission",
    active: "Active",
    create: "Create",
    isLiquid: "It is liquid",
    isMandatory: "Mandatory",
    numberOfChoices: "Maximum of choices (0 without limit)",
    print: "Print",
    showIds: "Show Ids",
    visible: "Visible",
    required: "Required",
    nutritionalUnit: "Unit of measurement",
    showOnMenu: "Show in menu",
    isCash: "And money?",
    getDeliveryAreaName: "Delivery area name",
    getRegionName: "Region area name",
    isProduct: "Applicable on products",
    isCompany: "Applicable in companies",
    isDoctor: "I'm a doctor",
    isPhysiotherapist: "I'm Physiotherapist",
    isNutritionist: "I'm Nutritionist",
    isPsychologist: "I'm Psychologist",
    CRM: "CRM",
    CREFITO: "CREFITO",
    CRP: "CRP",
    CRN: "CRN",
    placeholderInsurances: "Accepted Insurances",
    specialty: "Medical specialty",
    addressDoctor: "Service address",
    category: "Category",
    title: "Title",
    slug: "URL",
    abstract: "abstract",
    font: "Font",
    bodyText: "Content",
    emptyResult: "No results",
    lang: {
      ptbr: "Br",
      en: "EN"
    },
    isBookmarkable: "Can be added to favorites",
    confirmQuestion: "Are you sure you want to perform this action?",
    confirmDeleteFile: "Are you sure you want to remove this file?",
    confirmQuestionYes: "Yes",
    confirmQuestionCancel: "Cancel",
    search: {
      emptyResult: "No results found"
    },
    placeholderSpecialities: "Select specialties",
    approve: "To approve",
    reject: "Reject",
    doctors: "Doctors",
    remove: "Delete",
    videoUpload: "Upload video"
  },
  register: {
    increaseSails: "",
    discoverAdvantages: "",
    signIn: "Register your clinic!",
    ifAccountExist: "If you already have an account, please login below",
    orSignIn: "or create a new account to get started.",
    singIn: "Register your clinic!",
    orSingIn: "or create a new account to get started."
  },
  navBar: {
    signIn: "Register",
    logIn: "Enter",
    logOut: "Exit",
    orSignIn: "Or sign up",
    singIn: "Become a partner",
    start: "Begin"
  },
  image: {
    crop: "Crop",
    zoomIn: "Zoom In",
    zoomOut: "Zoom Out",
    rotateLeft: "Rotate Left",
    rotateRight: "Rotate Right",
    mirrorY: "Mirror Y",
    mirrorX: "Mirror X"
  },
  profile: {
    title: {
      client: "Information",
      admin: "Information",
      company: "Company Information",
      user: "User Information"
    },
    doctorRequest: {
      title: "Request status:",
      none: "Not yet requested, fill in your information to submit your registration for analysis",
      approved: "Okay",
      rejected: "Accepted",
      pending: "Pending"
    },
    profileRequest: {
      title: "Request status to receive exams:",
      none: "Not yet requested, add a photo that shows your face and a document",
      approved: "Okay",
      rejected: "Rejected",
      pending: "Pending"
    },
    youAreSure: "Reject?",
    youAreWantCancelOrder: "",
    youAreYes: "Yes",
    youAreNo: "Not",
    cancelReason: "Reason for rejection"
  },
  promotion: {
    step: "Stage",
    of: "of",
    createPromotion: "Create promotion",
    editPromotion: "Edit promotion",
    infoSub: "Promotion Information",
    line1: "Line1",
    line2: "Line2",
    description: "Promotion Description",
    behaviorSub: "Promotion operation",
    behaviorAll: "Apply to all orders",
    behaviorAllDesc: "Apply promotion to all orders from any customer.",
    behaviorFirst: "Apply only to the customer's first order",
    behaviorFirstDesc: "Apply promotion only upon the first request of a new customer.",
    behaviorCoupon: "Apply with promotional code",
    behaviorCouponDesc: "Apply promotion by means of a promotional code.",
    typeSub: "Promotion type",
    typeDelivery: "Delivery free",
    typeDeliveryDesc: "Exemption from the rate of delivery of orders via delivey.",
    typePercent: "Percent discount",
    typePercentDesc: "Apply a percentage discount on the value of the products.",
    typeValue: "Discount amount",
    typeValueDesc: "Discard a given value at the end of the order.",
    typeGift: "Toast",
    typeGiftDesc: "Include one or more products for free upon request.",
    applyInSub: "Scope of promotion",
    applyInAll: "Full menu",
    applyInAllDesc: "The promotion will apply to all items on your menu.",
    applyInCategories: "Selected Categories",
    applyInCategoriesDesc: "Select one or more categories from the menu to apply the promotion.",
    applyInProducts: "Selected Products",
    applyInProductsDesc: "Select specific products to which the promotion applies.",
    configPromotion: "Set up promotion",
    durationSub: "Duration and validity",
    isValidity: "Duration determined",
    isValidityDesc: "Determine a date to end the promotion automatically.",
    isRecurrent: "Recurring Promotion",
    isRecurrentDesc: "Schedule the promotion to automatically repeat itself.",
    restrictionsSub: "Promotion Restrictions",
    isQuantity: "Limit amount",
    isQuantityDesc: "Determine an amount of requests to end the promotion.",
    isTakeOut: "Take-out",
    isTakeOutDesc: "Promotion applies to products withdrawn from the store.",
    isDelivery: "Delivery",
    isDeliveryDesc: "Promotion applies to products delivered via delivery.",
    isMinValue: "Minimum value",
    isMinValueDesc: "Promotion applies to orders with a minimum value.",
    detailsPromotion: "Promotion Details",
    discountValueSub: "Discount amount",
    discountPercent: "Percent discount",
    discountValue: "Discount amount",
    recurrentNot: "Do not repeat the promotion",
    recurrentNotDesc: "Promotion will not be repeated",
    recurrentSub: "Promotion recurrence",
    recurrentWeekly: "Weekly",
    recurrentWeeklyDesc: "The promotion will be repeated at the same time once a week",
    recurrentDaily: "Daily",
    recurrentDailyDesc: "The promotion will be repeated every day at the same time",
    recurrentMonthly: "Monthly",
    recurrentMonthlyDesc: "The promotion will be repeated on the same day and time every month",
    limitQuantity: "Order limit",
    minValue: "Minimum value",
    dateBegin: "Start date",
    dateEnd: "End date",
    hourBegin: "Start Time",
    hourEnd: "End time",
    recurrentWeeklySub: "Select the days of the week",
    recurrentMonthlySub: "Select the day of the month",
    duration: "Duration of promotion"
  },
  calendar: {
    week: {
      "0": "Sunday",
      "1": "Monday",
      "2": "Tuesday",
      "3": "Fourth",
      "4": "Fifth",
      "5": "Friday",
      "6": "Saturday"
    }
  },
  access: {
    title: "Access",
    admin: "Administrator",
    editor: "Editor",
    masterEditor: "Master Editor",
    attendant: "Secretary",
    delivery: "Deliveryman",
    doctor: "Doctor"
  },
  tools: {
    title: "Tool"
  },
  deliveryOptions: {
    deliveryConfig: "Delivery configuration",
    deliveryErrorLatlng: "Set the business address first",
    kmValue: "Delivery value per km",
    minValue: "Minimum value",
    roundAt: "Round to each"
  },
  otherOptions: {
    acceptDelivery: "Allows delivery orders",
    acceptDeliveryDesc: "Accept deliveries",
    acceptPaymentOutApp: "Accepts payment outside the app",
    acceptPaymentOutAppDesc: "Accepts payment outside the App",
    acceptSails: "Accepts in-app sales",
    acceptSailsDesc: "Accepts sales",
    acceptTakeAtEstablishment: "Allows requests to be removed at the establishment",
    acceptTakeAtEstablishmentDesc: "Accept Takeout",
    otherOptionsSub: "Other configurations",
    showOnlyCategoryOnMenu: "Show only Subcategories in Menu",
    showOnlyCategoryOnMenuDesc: "One-level menu",
    acceptTakeout: "Accepts Takeout",
    acceptOnSite: "Accepts pick up at store",
    timeForDelivery: "Average delivery time",
    timeForDeliveryMin: "In",
    timeForDeliveryMax: "Up until",
    timeForPickupAndOnSite: "Average withdrawal time",
    timeForPickupAndOnSiteMin: "In",
    timeForPickupAndOnSiteMax: "Up until"
  },
  product: {
    nutritionalName: "Name",
    nutritionalValue: "Value",
    nutritionalPortion: "Portion",
    nutritionalValuePortion: "Value Portion",
    nutritionalValueVd: "VD %",
    vdDaily: "VD Daily",
    description: "description",
    name: "Name",
    nutritional: "Nutritional",
    portion: "Portion",
    value: "Price",
    placeholderTags: "Choose Tags",
    placeholderNutritional: "Choose nutritional items",
    placeholderIngredients: "Choose the ingredients",
    notVisible: "Not visible",
    category: "Category",
    variantName: "Variation",
    variantValue: ""
  },
  multiSelect: {
    selectLabel: "Press enter to select",
    selectGroupLabel: "Press enter to select group",
    selectedLabel: "Selected",
    deselectLabel: "Press enter to remove",
    deselectGroupLabel: "Press enter to deselect group",
    placeholder: "Select option",
    selectPermission: "Select permissions",
    selectSchedules: "Select the calendars that this user will have access to",
    selectVideos: "Select videos"
  },
  additional: {
    additionals: "Additional",
    editAdditional: "Edit Additional",
    freeText: "Free options",
    itemTitle: "Option name",
    itemValue: "Option value",
    placeholder: "Applicable Categories",
    title: "Additional",
    type: "Type",
    variation: "Variants",
    inputTitle: "Title",
    flavor: "Multiple flavors",
    allVariations: "All variations"
  },
  listItems: {
    noGroup: "Uncategorized Product"
  },
  orderStatus: {
    canceled: "Order Canceled Request",
    confirmed: "Order Confirmed",
    deliveryReady: "Delivery Completed",
    takeoutReady: "Order Completed",
    inPreparation: "In preparation",
    makingOrder: "Riding Order",
    waitDelivery: "Awaiting Delivery",
    onDelivery: "In delivery",
    waitConfirm: "Waiting confirmation",
    waitPayment: "Awaiting payment",
    waitTakeout: "Waiting for Withdrawal"
  },
  orderType: {
    takeout: "Takeout",
    undefined: "Not defined"
  },
  paymentStatus: {
    confirm: "Payment confirmed",
    undefined: "Payment not defined",
    invalidPaymentMethod: "Invalid payment method",
    invalidPaymentCard: "Card error",
    invalidPaymentUser: "Invalid user",
    invalidPaymentUnknown: "Unknown payment error",
    invalidPaymentAlreadyEarly: "Payment already made",
    invalidPaymentLimitValue: "Sorry, but we have a limit of R $ 500.00 per card purchase.",
    iuguNotApproved: "Transaction denied",
    iuguResponseUnknown: "Error processing payment",
    iuguPaymentError: "Error processing payment!",
    iuguPaymentErrorCatch: "Error processing payment !!",
    iuguRefundErrorCatch: "Error reversing payment",
    iuguRefundErrorResult: "Error reversing payment!",
    iuguPending: "Pending",
    iuguPaid: "Pay",
    iuguCanceled: "Canceled",
    iuguDraft: "Draft",
    iuguPartiallyPaid: "Partially Paid",
    iuguRefunded: "Refunded",
    iuguExpired: "Expired",
    iuguInProtest: "In protest",
    iuguChargeback: "Disputed",
    iuguInAnalysis: "In Review",
    iuguUndefined: "Undefined"
  },
  paymentOptions: {
    bankCheck: "Payment in check",
    creditCard: "Credit Card Payment",
    debitCard: "Debit Card Payment",
    money: "Cash payment",
    paymentOptionsSub: "Payment options",
    acceptPaymentOnApp: "Accept payment on the App",
    acceptCashPayment: "Accept cash",
    acceptPaymentOnDelivery: "Payment accepted on delivery",
    placeholderCards: "Add accepted cards",
    auto_withdraw: "Automatic withdrawal",
    auto_advance: "Automatic Anticipation.",
    iuguInfo: "Payment account information",
    createIuguVerify: "Submit data for validation",
    alertNotAvailableForIugu: "This company was not able to create a payment account",
    createIugu: "Create payment account",
    notAvailableForIugu: "This company was not able to create a payment account, please contact support.",
    createIuguVerifyAccept: "Account Accepted",
    createIuguVerifyBank: "Check Bank",
    infoTitle: "Contact Information",
    createIuguTitle: "Create Receiving Account",
    formTitle: "Form",
    bankTitle: "Create or update bank",
    createIuguPrevious: "Account already created",
    needCreateIugu: "You must create a receipt subaccount first",
    receiveInSubAccount: "Change to subaccount receipt"
  },
  iugu: {
    name: "Company Name",
    is_verified: "Checked?",
    can_receive: "Can you receive?",
    last_verification_request_status: "Last scan status",
    last_verification_request_data: "Data from the last scan",
    last_verification_request_feedback: "",
    change_plan_type: "Plan",
    balance: "Balance",
    balance_in_protest: "Protest balance",
    balance_available_for_withdraw: "Balance available for delivery",
    protected_balance: "Protected balance",
    payable_balance: "Balance to pay",
    receivable_balance: "Balance receivable",
    commission_balance: "Commission balance",
    volume_last_month: "Last month",
    volume_this_month: "This month",
    taxes_paid_last_month: "Fees paid in the last month",
    taxes_paid_this_month: "Fees paid this month",
    has_bank_address: "Has bank address",
    commissions_percent: "Go Tap Fee",
    configuration: {
      auto_withdraw: "Automatic withdrawal"
    },
    accountVerification: {
      title: "Account verification",
      price_range: "Maximum sale amount ('Up to R $ 100.00', 'Between R $ 100.00 and R $ 500.00', 'More than R $ 500.00')",
      physical_products: "Physical products?",
      business_type: "Describe your business",
      person_type: "Type of registration",
      person_typeFisica: "Physical person",
      person_typeJuridica: "Legal person",
      automatic_transfer: "Automatic transfer (yes recommended)",
      name: "Name",
      company_name: "Company Name",
      cpf: "CPF",
      cnpj: "CNPJ",
      resp_name: "Responsible name",
      resp_cpf: "CPF of the person in charge",
      telephone: "telephone",
      account_type: "Account Type",
      bank: "Bank",
      account_typeCorrente: "Chain",
      account_typePoupanca: "Savings",
      bank_ag: "Agency (9999-9)",
      bank_cc: "Account (999999-9)",
      document: "Bank voucher (The bank address voucher is any document that contains the bank account information (Name of the Bank, Branch, Account) as well as the company name or CNPJ for Legal Entity)"
    }
  },
  companyInfo: {
    title: "Company Info",
    das: "Das",
    as: "At",
    isClose: "Closed",
    attendanceTitle: "Service Hours",
    paymentTitle: "Accepted payment methods"
  },
  timeElapsed: {
    "12": "12 hours",
    "24": "24 hours",
    "48": "2 days",
    "120": "5 days",
    "240": "10 days",
    "720": "30 days",
    label: "Order Period"
  },
  notifications: {
    examTitle: "Your exam is available"
  },
  rules: {
    adm: {
      adm: "Administrator",
      sell: "Salesman"
    },
    comp: {
      adm: "Administrator",
      edit: "Editor",
      mEdit: "Master Editor",
      deli: "Delivery",
      att: "Clerk",
      doc: "Doctor"
    }
  },
  router: {
    accessDenied: "Access denied"
  },
  orderError: {
    statusError: "The bag is in an invalid state to be transformed into an order",
    permissionDenied: "The user is not allowed to change this bag",
    unknown: "Unknown error",
    paymentError: "Error making payment",
    bagError: "There is an error with the bag"
  },
  maps: {
    areaDeliveryInfo: "Delivery area"
  },
  prescription: {
    searchMedicament: "Medicament",
    dosage: "Dosage",
    selectPatient: "Select the patient",
    pdf: {
      medicament: "Medicament: ",
      dosage: "Dosage: ",
      CRM: "CRM: "
    },
    patient: "Patient",
    doctor: "Doctor"
  },
  exam: {
    addItem: "Add item",
    typeLst: {
      text: "Text",
      select: "Selection",
      checkbox: "Options",
      number: "Number"
    },
    type: "Value type",
    referenceType: "Reference value type",
    referenceText: "Reference text",
    referenceMin: "Above",
    referenceMax: "Below",
    options: "Options",
    addOption: "Add option",
    optionName: "Option name",
    unit: "Unit of measurement",
    type2: "Value type (2)",
    referenceType2: "Reference value type (2)",
    referenceText2: "Reference text (2)",
    referenceMin2: "Above (2)",
    referenceMax2: "Under (2)",
    options2: "Options (2)",
    addOption2: "Add Option (2)",
    optionName2: "Option name (2)",
    unit2: "Unit of measure (2)",
    unitLst: {
      none: "Without drive",
      ml: "ml",
      milhoesmm3: "million/mm³",
      percent: "%",
      gDL: "g/dL",
      fL: "fL"
    },
    addSection: "Add section",
    nameOfSection: "Section name",
    section: "Section",
    has2values: "Does it have secondary value?",
    isEvaluated: "Has it been analyzed?",
    template: "Template",
    addHealthDataFields: "Add health data type",
    selectDoctors: "Select doctors",
    selectPatient: "Select the patient",
    date: "Exam date",
    fileType: {
      pdf: "PDF",
      image: "Image",
      pdfImage: "PDF and Image"
    },
    filePdf: "Add PDF",
    fileImage: "Add images",
    selectCompany: "Clinic",
    removed: "This exam no longer exists",
    patient: "Patient",
    evaluatedYes: "Examination dismembered",
    evaluatedNot: "Examination not dismembered",
    company: "Clinic"
  },
  blog: {
    title: "",
    notFound: "Content not found",
    isNotPostVideo: "No photos or videos added"
  },
  chat: {
    newChat: "New conversation",
    writeSomething: "Say something",
    dateFormat: "DD MMMM YYYY",
    hourFormat: "HH:mm",
    notification: {
      title: "New message received",
      body: ""
    }
  },
  home: {
    resources: "Resources",
    solution: "A complete solution for remote consultation",
    yourData: "A virtual waiting room for your digital consultation",
    downloadApp: "Download and install the app on your phone to see your doctor without leaving home",
    exam: "Make your exams more practical",
    scheduleTitle: "Consultation schedule",
    schedule: "When scheduling appointments with clinics that offer the Mais Saúde service, the dates and times of care are automatically updated in the application.",
    contentTitle: "Exclusive Content",
    content: "On the app's home screen you can find exclusive content from the Mais Saúde channel, with health and technology tips and news to keep you up to date.",
    waitRoomTitle: "Waiting room",
    waitRoom: "Just before your appointment starts you have access to a digital waiting room, with interactive content and personalized videos from the clinic, while you wait for your doctor.",
    recordTitle: "Digital Health Record",
    record: "When being attended to, your doctor has access to a complete digital medical record, where your data is organized and can be consulted at each appointment.",
    paymentTitle: "Integrated Payments",
    payment: "In addition to integration with health plans, private payments can be made quickly and easily through the application, simply accept the request sent by the clinic.",
    privacyTitle: "Privacy and Security",
    privacy: "All patient and clinic information is stored securely, meeting technical requirements and ensuring data confidentiality.",
    forDoctors: "For doctors and clinics",
    subscribe: "Sign up now and discover all the professional resources that Teleconsulta Mais Saúde offers.",
    teleconsult: "More Health Teleconsultation - 2020"
  },
  emailReset: {
    subject: "Reset password",
    head: "Password reset email",
    body: "",
    link: "Reset password"
  },
  emailFirstPassword: {
    subject: "First access password",
    head: "User:",
    body: "Your password for the first login is:",
    email: "Access email:",
    link: "First access"
  },
  videoStatus: {
    UNKNOWN: "Pending",
    COMPLETE: "Ready"
  },
  dropzoneFCM: {
    title: "Drop files here or click to upload.",
    info: ""
  },
  uploadStatus: {
    COMPLETE: "Ready",
    UNKNOWN: "Preview not available",
    PENDING: "Processing"
  },
  placeholder: {
    name: "Name",
    nameCompany: "Clinic name",
    nameCorporate: "Clinic name",
    namePacient: "Name of the patient",
    nameUser: "Username",
    cpf: "000.000.000-00",
    cnpj: "00,000,000 / 0000-00",
    email: "email@domain.com",
    minimumCaracters: "At least 4 characters",
    birthDate: "00/00/0000",
    gender: "M: Male, F: Female",
    cell: "(00) 00000-0000",
    addressPacient: "Street name, number",
    descriptionCompany: "A brief description of the clinic",
    newPassword: "New user password:",
    alterPassword: "Change user password:",
    clinicSituation: "Clinic situation:",
    active: "Active",
    desative: "Disabled",
    medicalAgreement: "Agreement name",
    nameTypeCare: "Service type name",
    timeCare: "00 minutes",
    slug: "Keywords, important for url",
    titleBlog: "Title of article / blog",
    abstractBlog: "Brief summary",
    fontBlog: "Where come from this content",
    titleVideo: "Enter video title",
    category: "Select a category",
    tags: "Tags",
    idVideo: "Enter the video id taken on youtube",
    nameDoctor: "Enter the name of the doctor",
    nameCategory: "Enter category name",
    specialty: "Enter the doctor's specialty",
    crefito: "",
    crm: "0000/MS",
    crn: "",
    crp: "",
    permission: "Select permissions",
    schedules: "Select the calendars that this user will have access to",
    nameVideo: "Enter the name of the video",
    namePlaylist: "Enter playlist name",
    choseClinics: "Choose clinics",
    choseVideos: "Choose the videos that will be part of the playlist"
  },
  obs: {
    permissionDoctor: "Note: This user needs a doctor's permission.",
    requiredFields: "Note: Fields with (*) are mandatory."
  },
  validateActive: {
    msg: "To activate a clinic you need:",
    doctor: "At least one registered doctor;",
    playlist: "At least one registered and active playlist;",
    schedules: "At least one registered agenda;",
    active: "Clinic Activated",
    desactive: "Disabled Clinic"
  },
  tele: {
    offerConnection: "Start Call",
    answerConnection: "Accept Call",
    waitingConnection: "Waiting for Call",
    errorWaitConnection: "Wait for the patient to initiate the call",
    hangUp: "End Call",
    errorNavigator: "Couldn't get image, is your browser up to date?",
    errorHttp: "Use https to access this feature",
    errorStartingCam: "Error starting the camera",
    errorStartingScreen: "Error when starting screen sharing",
    errorStartRemoteVideo: "Error starting remote video component",
    errorStartLocalVideo: "Error starting local video component",
    errorOnRecord: "Error starting recording",
    errorUnknown: "Unknown error",
    errorTypeRecord: "A compatible media type was not found in your browser for recording",
    warnOnRecordType: "A compatible media type was not found in your browser for recording, we will try to record on webm",
    warnChangeTypeOnRecord: "This change will take effect on the next recording",
    warnNoTrackToRecord: "No media available for recording",
    selectVideo: "Select the video",
    selectInputAudio: "Select the microphone",
    selectOutputAudio: "Select the speaker",
    selectRecordType: "Select the recording type",
    startSharedScreen: "Share screen",
    stopSharedScreen: "Stop screen sharing",
    configHeader: "Settings",
    configVideo: "Camera",
    configAudio: "Audio",
    configRecord: "Recording",
    config: "Config",
    changeMicrophoneOn: "Mute",
    changeMicrophoneOff: "Unmute",
    changeVideoOn: "Stop Video",
    changeVideoOff: "Start Video",
    changeShareScreenOn: "Stop screen",
    changeShareScreenOff: "Share screen",
    changeRecordOn: "Stop recording",
    changeRecordOff: "Start recording",
    connectionState: {
      new: "Initializing ...",
      connecting: "Connecting ...",
      connected: "Connected",
      disconnected: "Disconnected",
      failed: "Connection fail",
      closed: "Connection closed"
    }
  }
}