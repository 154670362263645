<script>
//  import { configComputed} from '@state/helpers'
 // let {translateBd} = require('@i18n');
  export default {
    model: {
      prop: 'lock',
      event: 'change'
    },
    data () {
      return {
        isClose: false,
        versionNumber: process.env.VUE_APP_VERSION_NUMBER,
        version: process.env.VUE_APP_VERSION
      }
    },
    computed: {
      config() {
        return this.$store?.state?.config || {};
      },
      lockedMsg() {
        if(this.config.configWeb?.minVersion?.msg && this.config?.configWeb?.minVersion?.version
            && this.config?.configWeb?.minVersion?.lock
            && parseInt(this.config.configWeb.minVersion.version)>=parseInt(this.versionNumber)) {
          return this.$trdb(this.config.configWeb?.minVersion?.msg);
        }
        if(this.config?.configWeb?.maintenance?.msg && this.config?.configWeb?.maintenance?.status
            && this.config?.configWeb?.maintenance?.lock ) {
          return this.$trdb(this.config?.configWeb?.maintenance?.msg);
        }
        return null;
      },
      minVersionMsg() {
        if(this.config.configWeb?.minVersion?.msg && this.config?.configWeb?.minVersion?.version
            && parseInt(this.config.configWeb.minVersion.version)>=parseInt(this.versionNumber)) {
          return this.$trdb(this.config.configWeb?.minVersion?.msg);
        }
        return null;
      },
      maintenanceMsg() {
        if(this.config?.configWeb?.maintenance?.status && this.config?.configWeb?.maintenance?.msg) {
          return this.$trdb(this.config?.configWeb?.maintenance?.msg);
        }
        return null;
      },
    },
    watch: {
      config(value, oldValue) {
        if(value.maintenance && value.maintenance.status && value.maintenance.msg && value.maintenance.lock) {
          this.$emit('change', true)
        }
        else if(value.maintenance && value.minVersion.version && value.minVersion.msg && value.minVersion.lock && parseInt(value.minVersion.version)>=parseInt(this.versionNumber)) {
          return this.$emit('change', true)
        }
        else {
          return this.$emit('change', false)
        }
      }
    },
    methods: {
      reload() {
        location.reload(true);
      },
      close() {
        this.isClose = true;
      }
    },
  }

</script>


<template>
<div v-if="maintenanceMsg || minVersionMsg" :class="[$style.config]" >
  <div v-if="maintenanceMsg" :class="[$style.maintenance]">
    {{maintenanceMsg}}
  </div>
  <div v-if="minVersionMsg" :class="[$style.minVersion]" @click="reload()">
    {{minVersionMsg}}
  </div>
  <div :class="[$style.closeButton]" @click="close"><MdIcon>close</MdIcon></div>
</div>
</template>


<style lang="scss" module>
@import '@design';

  .config {
    @extend %defaultAlert;

    width: 100%;
    padding: 10px;
    position: fixed;
    border: 1px solid red;
    display: flex;

    .minVersion {
      flex: 1;
      cursor: pointer;
    }

    .maintenance {
      flex: 1;
    }

    .closeButton {
      flex: 0;
      color: #bb2f2c;
      cursor: pointer;
      top: 9px;
      right: 30px;

      i {
        color: #bb2f2c!important;
      }
    }
  }




</style>
