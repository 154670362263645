import moment from 'moment'
import firebase from 'firebase/app'
let {translateDb, i18nConfig, languages} = require('@i18n');


export default {

  install (Vue, options) {
    /*
    Vue.mixin({
      beforeDestroy: function () {

        console.log('unsubscribe function', Vue.prototype.$fcmUnsubscriber);
        // for(let us of Vue.prototype.$fcmUnsubscriber) {
        //   if(typeof us === 'function') {
        //     us();
        //   }
        // }
      //  Vue.prototype.$fcmUnsubscriber = [];
      }
    });
    Vue.prototype.$fcmUnsubscriber = [];
    */


    Vue.prototype.$eventHub = new Vue(); // Global event bus

    Vue.prototype.$trdb = function (obj) {
      if(this.$i18n && this.$i18n.locale) {
        return translateDb(this.$i18n.locale, obj);
      }
      return translateDb(i18nConfig.fallbackLocale, obj);
    };

    Vue.prototype.$trdbLocale = function () {
      if(this.$i18n && this.$i18n.locale) {
        return (languages[this.$i18n.locale] || {}).database;
      }
      return (languages[i18nConfig.fallbackLocale] || {}).database;
    };

    Vue.prototype.$showMsg = function ({title='', text=null, timer=1500}={}) {
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: title ,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showWarning = function ({title='', text=null, timer=2500}={}) {
      if(!title && !text) {
        title = this.$t('form.updateSuccess');
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        title: title ,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showSuccess = function ({title='', text=null, timer=1500}={}) {
      if(!title && !text) {
        title = this.$t('form.updateSuccess');
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: title ,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showError = function ({title='', text=null, timer=3500}={}) {
      if(!title && !text) {
        title = this.$t('form.error.update');
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'error',
        title: title,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showConfirm = async function ({title='', text=null, timer=3500}={}) {
      if(!title && !text) {
        title = this.$t('form.confirmQuestion');
      }
      let result = await this.$swal({
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonText: this.$t('form.confirmQuestionYes'),
        cancelButtonText: this.$t('form.confirmQuestionCancel')
      });
      if(result.value) {
        return true;
      }
      return false
    };

    Vue.prototype.$newId = function () {
      return firebase.firestore().collection('id').doc().id;
    };

    Vue.prototype.$validateRules = function () {
      return {
        required: value => !!value || this.$t('form.error.required'),
        counter: value => value.length <= 20 || this.$t('form.error.maxLength'),
        minLength: value => value.length > 6 || this.$t('form.error.minLength'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('form.error.email')
        },
        numberDotComma: value => {
          const pattern = /^[0-9.,]*$/
          return pattern.test(value) || this.$t('form.error.invalidValue')
        },
        number: value =>{
          const pattern = /^[0-9]*$/
          return pattern.test(value) || this.$t('form.error.justNumbers')
        },
        numberAndDot: value =>{
          const pattern = /^[0-9.]*$/
          return pattern.test(value) || this.$t('form.error.justNumbers')
        },
        hour: value => {
          if (!value) { return true }
          if (value.length < 5 ){
            return this.$t('form.error.hour')
          }
          if(parseInt( value.substr(0, 2)) > 23 || parseInt( value.substr(3, 2)) > 59){
            this.$showError({text: this.$t('form.error.hour')});
            return this.$t('form.error.hour')
          }
          return true;
        },
        cpf: value => {

          if (!value)
            return true;

          value = value.replace(".", "");
          value = value.replace(".", "");
          value = value.replace(".", "");
          value = value.replace("-", "");

          let sum;
          let rest;
          let returnInvalid = false;
          sum = 0;
          if (value === "00000000000")
            returnInvalid = true;

          for (let i = 1; i <= 9; i++)
            sum = sum + parseInt(value.substring(i - 1, i)) * (11 - i);

          rest = (sum * 10) % 11;

          if ((rest === 10) || (rest === 11))
            rest = 0;
          if (rest !== parseInt(value.substring(9, 10)))
            returnInvalid = true;

          sum = 0;
          for (let i = 1; i <= 10; i++)
            sum = sum + parseInt(value.substring(i - 1, i)) * (12 - i);

          rest = (sum * 10) % 11;

          if ((rest === 10) || (rest === 11))
            rest = 0;
          if (rest !== parseInt(value.substring(10, 11)))
            returnInvalid = true;

          if (returnInvalid) {
            return this.$t('all.invalidCPF');
          }
          return true;
        }
      }

    };



    Vue.prototype.moment = moment;
  }
}
