import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
    currentCompany: (state) => state.currentCompany,
    currentWorkspace: (state) => state.currentWorkspace,
  }),
  ...mapGetters('auth', ['loggedIn']),
};

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'companyRegister', 'modelCompanyUpdate', 'listCompanies',
  'onAuthStateChanged', 'getAdminMailByFakeMail', 'modelClientUpdate', 'modelAdminUpdate', 'modelCompanyUserUpdate',
  'logInSocial', 'startLogin', 'endLogin', 'logInPhone', 'logInClient', 'companyChangeOpen', 'registerClient']);


export const timeComputed = {
  ...mapState('time', {
    nowMinuted: (state) => state.nowMinuted,
  }),
};

export const configComputed = {
  ...mapState('config', {
    config: (state) => state.config,
  }),
};

export const chatComputed = {
  ...mapState('chat', {
    chatHighlighteds: (state) => state.highlighteds,
    chatContacts: (state) => state.contacts,
    chatCurrent: (state) => state.current,
    chatCurrentMessages: (state) => state.currentMessages,
    chatCurrentId: (state) => state.currentId,
    chatUserKey: (state) => state.userKey,
  }),
};


export const chatMethods = mapActions('chat', ['setCurrent', 'close']);
