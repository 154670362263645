module.exports = {
  order: {
    title: "Pedido",
    order: "Pedido",
    date: "Data/Hora",
    via: "Via",
    paymentTitle: "Pagamento",
    paymentOnTakeout: "Pagamento na Retirada",
    clientName: "Nome",
    clientPhone: "Telefone",
    clientDocumentID: "CPF",
    product: "Produto",
    qtd: "Qtd",
    viaOption: {
      "0": "Caixa / Balcão",
      "1": "Interna / Cozinha",
      "2": "Entregador"
    },
    subTotal: "SubTotal",
    total: "Total",
    credits: "Créditos",
    deliveryTax: "Entrega",
    changeFor: "Troco para",
    change: "Troco",
    address: "Endereço",
    paymentType: "Tipo de pagamento",
    type: "Tipo",
    deliveryAddress: "Endereço",
    deliveryType: "Tipo de pedido",
    value: "Valor",
    itemName: "Produto",
    itemQtd: "Quantidade",
    itemValueTotal: "Total",
    payment: "Pagamento",
    status: "Status",
    time: "Data",
    newOrderNotificationTitle: "Novo pedido",
    newOrderNotificationBody: "Acaba de ser recebido um novo pedido",
    changeForStatus: "Alterar para",
    number: "Número do pedido",
    code: "Código do pedido",
    numberSlim: "Número",
    codeSlim: "Código",
    distance: "Distância",
    itemObservation: "Obs",
    obsOrder: "Obs pedido",
    ingredientsRestrictions: "Restrição de ingredientes"
  },
  apiErrorCode: {
    statusError: "Estado inválido",
    permissionDenied: "O usuário não tem permissão para executar essa ação",
    unknown: "Erro desconhecido",
    paymentError: "Erro ao efetuar o pagamento",
    bagError: "Há algum erro com a sacola",
    inputError: "Erro nos parâmetros",
    exhaustedAttempts: "Muitas tentativas",
    paymentInvalidValue: "Valor de pagamento inválido",
    notFound: "Não encontrado",
    bagEmptyAddress: "Pedido sem endereço",
    bagEmptyProduct: "Pedido vazio",
    bagStatusError: 'Pedido cancelado ou em estado inválido',
    iuguErrorCreateCompany: 'Erro ao criar conta no iugu',
  },
  format: {
    data: "DD/MM/YYYY",
    dateTime: "DD/MM/YYYY HH:mm"
  },
  deliveryType: {
    delivery: "Entrega",
    pickup: "Retirar na loja",
    onSite: "Pedido no estabelecimento"
  },
  message: {
    hello: "Olá mundo2"
  },
  login: {
    keepConnect: "Matenha-me conectado",
    emailAddress: "Endereço de email",
    access: "Acessar Clínica",
    accessCompany: "Acessar Clínica",
    errorCompany: "Nenhuma clínica associada a este email",
    login: "Log In",
    goReset: "Esqueci minha senha",
    goLogin: "Retornar ao login",
    accessClient: "Acesso Cliente",
    accessAdmin: "Acesso Admin",
    facebook: "Entrar com Facebook",
    google: "Entrar com Google",
    phone: "Entrar com Telefone",
    apple: "Entrar com Apple",
    getPhoneNumber: "Insira seu número de telefone",
    getPhoneCode: "Insira seu código de verificação enviado ao seu celular",
    resetCompany: "Resetar Senha",
    reset: "Enviar email",
    error: {
      generic: "Houve algum erro ao efetuar login",
      phoneEmpty: "Número de telefone vazio",
      phoneCodeEmpty: "Código de verificação vazio",
      loginTitle: "Erro Ao efetuar login",
      providerTitle: "Erro ao adicionar provider"
    },
    providerCode: {
      "auth/invalid-email": "Email inválido",
      "auth/user-disabled": "Conta desabilitada",
      "auth/user-not-found": "Usuário não encontrado",
      "auth/wrong-password": "Senha incorreta",
      "auth/credential-already-in-use": "Credencial já utilizada",
      "auth/too-many-requests": "Muitas tentativas, tente novamente mais tarde",
      "auth/operation-not-allowed": "Tipo de login não configurado, tente outra forma de login"
    },
    addFacebook: "Adicionar Facebook",
    addGoogle: "Adicionar Google",
    addApple: "Adicionar Apple",
    providers: "Métodos de autenticação",
    remove: "Remover",
    providerId: "provider",
    email: "email",
    uid: "uid",
    changePassword: "Alterar senha",
    addPassword: "Adicionar Senha",
    resetClient: "Resetar senha",
    registerClient: "Registrar",
    register: "Efetuar cadastro",
    googleRegister: "Registrar com Google",
    facebookRegister: "Registrar com Facebook",
    emailRegister: "Registrar com email",
    appleRegister: "Registrar com Apple",
    phoneRegister: "Cadastro com Telefone",
    resetPassword: "Resetar senha",
    makeRegister: "Efetuar cadastro",
    accessOng: "Acessar Ong"
  },

  all: {
    similarWords: "Palavras semelhantes",
    notSelected: "Não selecionado",
    video: "Vídeo",
    views: "Visualizações",
    thumbnailVideo: "Thumbnail do vídeo",
    sizeThumbnail: "1080 x 1920",
    hospital: "Hospital",
    healthUnit: "Unidade de saúde",
    viewsProfile: "Visualizações do perfil: ",
    viewsVideo: "Visualizações do vídeo: ",
    insurancesAccepts: "Convênios aceitos",
    name: "Nome",
    typePayments: "Tipos de pagamentos aceitos",
    companyData: "Dados da clínica onde atende",
    configSchedule: "Horários da clínica",
    aboutMe: "Sobre mim",
    specialties: "Especialidades",
    education: "Formação",
    professionalExperiences: "Experiência profissionais",
    healthInVideos: "Saúde em vídeos",
    font: "Fonte: ",
    noFont: "Fonte indisponível",
    selected: "Selecionados",
    all: "Todos",
    othersCategories: "Outras",
    others: "Outros",
    adminUsers: "Administradores",
    anamnesis: "Anamnese",
    clinicalStaff: "Corpo Clínico",
    userDeleteExistAlready: "Há um usuário deletado associado a este email, uma email para reset de e reativação foi enviado para ele.",
    teleconsultation: "Teleconsulta",
    enablePlaylistToCompany: "Você precisa associar pelo menos uma playlist à esta clínica antes de ativá-la",
    understood: "Entendi",
    accessDenied: "Você não tem acesso a esta área",
    financialReport: "Relatório Financeiro",
    viewed: "Visto",
    activeDesactive: "Ativar/Desativar",
    color: "Cor",
    yes: "Sim",
    no: "Não",
    professional: "Profissional",
    newPatient: "Paciente novo",
    patientNew: "Novo Paciente",
    startOfConsultation: "Início da Consulta",
    endOfConsultation: "Fim da Consulta",
    statusWaitingRoom: {
      canceledByPatient: "Cancelado pelo Paciente",
      canceledByDoctor: "Cancelado pelo Médico",
      inConsultation: "Em consulta",
      insideWaitingRoom: "Aguardando",
      normal: "Agendado",
    },
    selectCareTypeIdRequired: "Selecione o Tipo Atendimento",
    selectTelemedicine: "Modalidade",
    selectMedicalAgreements: "Convênio",
    telemedicine: "Teleconsulta",
    presential: "Presencial",
    closed: "Fechado",
    day: "Dia",
    week: "Semana",
    payment: {
      paymentByApp: "Pagamento pelo App",
      menuPayment: "Pagamento",
      dateOfPayment: "Data do Pagamento",
      dateOfPaymentBegin: "Pagamento Início",
      dateOfPaymentEnd: "Pagamento Fim",
    },
    consultationDateBegin: "Consulta Início",
    consultationDateEnd: "Consulta Fim",
    posts: "Posts",
    contentMixes: "Mixes de conteúdo",
    editorials: "Editoriais",
    recentsPublications: "Publicações recentes",
    choseCompaniesHint: "Escolha as clínicas em que esta playlist ficará disponível.",
    choseDoctorsHint: "Escolha os médicos em que esta playlist ficará disponível.",
    choseCompanies: "Escolha as clínicas",
    choseDoctors: "Escolha as médicos",
    choseVideos: "Escolha os vídeos para compor a playlist",
    comments: "Observações: ",
    lunchTimeConflict: "Não é possível agendar consulta dentro do horário do almoço!",
    officeHoursConflict: "Não é possível agendar consulta fora do horário do expediente!",
    blockHoursConflict: "Este horário está bloqueado!",
    dateConflict: "Não é possível agendar consulta nesta data!",
    selectPatient: "Selecione um Paciente",
    selectSchedule: "Selecione uma Agenda",
    noDoctors: "Sem médicos",
    schedules: {
      schedule: "Agenda",
      schedules: "Agendas",
      appointment: "Agendar consulta",
      agend: "Agendar",
      delete: "Excluir Agendamento",
      new: "Novo agendamento",
      saveSchedule: "Salvar Agendamento",
      startConsultation: "Iniciar atendimento",
    },
    waiting: "AGUARDANDO",
    waitingRoom: "Sala de Espera",
    items: "Itens",
    enable: "Habilita",
    disabled: "Desabilita",
    itemsByPage: "Items por Página",
    noDataText: "Nenhum item cadastrado",
    noDataResults: "Nenhum item encontrado pela busca",
    noResultsText: "Nenhum item encontrado pela busca",
    loadingItems: "Carregando items...",
    loadingPatients: "Carregando pacientes...",
    noPatients: "Nenhum paciente listado",
    back: "Voltar",
    consultations: "Consultas",
    payments: "Pagamentos",
    value: "Valor R$",
    doctor: "Médico",
    nutritionist: "Nutricionista",
    psychologist: "Psicólogo",
    physiotherapist: "Fisioterapeuta",
    personal: "Personal",
    dentist: "Dentista",
    therapist: "Terapeuta",
    caretaker: "Cuidador",
    type: "Tipo",
    date: "Data",
    typeService: "Tipo da Consulta",
    duration: "Duração",
    durationInMinutes: "Duração (min)",
    disable: "DESABILITADO",
    confirm: "CONFIRMAR",
    disableModal: "Deseja excluir?",
    disableModalMessage: "Ao confirmar, este item será excluído do sistema.",
    actions: "Ações",
    invalidCPF: 'CPF Inválido!',
    single: 'Solteiro',
    married: 'Casado',
    separate: 'Separado',
    divorced: 'Divorciado',
    widower: 'Viúvo',
    female: 'F',
    male: 'M',
    careTypes: 'Tipos de atendimento',
    careType: 'Tipo Atendimento',
    typeCompany: 'Perfil da Clínica',
    medicalAgreements: "Convênios",
    medicalAgreement: "Convênio",
    patients: "Pacientes",
    patient: "Paciente",
    playlists: "Playlists",
    videos: "Vídeos",
    restaurants: "Clínicas",
    restaurant: "Clínica",
    next: "Próximo",
    knowMore: "Saiba Mais",
    notImplemented: "Não implementado",
    attendance: "Atendimento",
    orders: "Pedidos",
    reviews: "Avaliações",
    promotions: "Promoções",
    settings: "Configurações",
    products: "Cardápio",
    delivery: "Entregas",
    schedule: "Horários",
    information: "Informação",
    informations: "Informações",
    performance: "Performance",
    history: "Histórico",
    demographics: "Público",
    of: "de",
    step: "Etapa",
    category: "Categoria",
    profile: "Perfil",
    categories: "Categorias",
    users: "Usuários",
    paymentOptions: "Pagamento",
    otherOptions: "Diversos",
    conclude: "Salvar",
    close: "Fechar",
    cancel: "Cancelar",
    nothingToList: "Lista vazia",
    search: "Pesquisar",
    productsCategories: "Categorias",
    productsSubCategories: "Sub Categorias",
    additionals: "Adicionais",
    createProduct: "Criar Produto",
    editCategory: "Editar Categoria",
    editProduct: "Editar Produto",
    editSubCategory: "Editar Subcategoria",
    settingsProducts: "Configuração do Cardápio",
    companiesTags: "Tags Empresa",
    productsNutritional: "Nutricional",
    productsNags: "Tags Produto",
    vdTotal: "VD total",
    categoriesProducts: "Categorias de Produtos",
    productsTags: "Tags de Produtos",
    productsIngredients: "Ingredientes",
    cards: "Cartões",
    companyConfigs: "Configurações",
    edit: "Editar",
    create: "Novo",
    clients: "Clientes",
    regions: "Regiões",
    region: "Região",
    regionUtcOffset: "UtcOffset",
    jobs: "Jobs",
    tags: "Tags",
    tagAssociate: "Tag associada",
    exams: "Meus Exames",
    examsClients: "Exames de clientes",
    prescriptions: "Minhas prescrições",
    prescriptionsClients: "Prescrições de clientes",
    blog: "Blog",
    blogVideos: "Blog Videos",
    faq: "FAQ",
    pages: "Páginas",
    blogCategories: "Categorias de Blog",
    insurances: "Convênios",
    medicament_upload: "Upload de lista de medicamentos",
    exams_template: "Templates de exames",
    changeImage: "Alterar imagem",
    healthDataFields: "Tipo de dados de saúde",
    units: "Unidade de medida",
    isPreUser: "Pré cadastro",
    specialities: "Especialidades",
    clinics: "Clínicas",
    clinic: "Clínica",
    videoCategories: "Categorias dos vídeos",
    company_tags: "Tags Empresa",
    product_nutritional: "Nutritivo",
    product_tags: "Tags Produto",
    about: "Sobre",
    forDoctors: "Para médicos",
    help: "Ajuda",
    subscriptions: "Assinaturas",
    resources: "Recursos",
    android: "App para Android",
    ios: "App para iOS",
    press: "Imprensa",
    recent: "Novidades recentes",
    status: "Status do serviço",
    ask: "Perguntas frequentes",
    support: "Suporte",
    allRight: "Todos os direitos reservados © 2020",
    examCategories: "Categorias de exame",
    leftFooter: "Canal Mais Saúde - 2020",
    centerFooter: "Canal Mais Saúde",
    textLeftFooter: "Todos os direitos reservados © Canal Mais Saúde",
    frstTxt: "Mais Saúde",
    scndTxt: "ADMINISTRADOR DO SISTEMA",
    Admin: "ADMINISTRADOR",
    doctors: "Médicos",
    desktop: "Computador Local",
    cloud: "Nuvem",
    videoNotReady: "Visualização do vídeo ainda não disponível",
  },
  support: {
    contact: "Entre em contato conosco pelos telefones e endereços de e-mail listados abaixo.",
    email: "suporte@teleconsultamaissaude.com.br",
    emailContact: "contato@teleconsultamaissaude.com.br",
    phone: "(67) 3306-3337",
    phoneTxt: "Telefone: ",
    comercial: "Comercial e cobranças",
    helpApp: "Ajuda com aplicativo",
    complaints: "Reclamações e elogios",
  },
  form: {
    createdAtShow: "Data de criação",
    rqe: "RQE",
    payment: {
      cash: "Dinheiro",
      debit: "Cartão de débito",
      credit: "Cartão de crédito",
      pix: "Pix",
    },
    instagram: "Instagram",
    facebook: "Facebook",
    youtube: "YouTube",
    site: "Site",
    image: "Imagem",
    availableForIugu: "Habilitar criação de conta no iugu",
    daysBlockedInvalid: "Data de Bloqueio Inválida!",
    hourServiceInvalid: "Horário de Atendimento Inválido!",
    hourBlockedInvalid: "Horário de Bloqueio Inválido!",
    idVideo: "Id Video",
    customLock: "Adiciona Bloqueio",
    customLocks: "Bloqueios Personalizados",
    searchPatients: "Pesquisar Pacientes",
    hour: "Hora",
    dateConsult: "Data da consulta",
    doctor: "Médico",
    week: {
      firstLetterSunday: 'D',
      firstLetterMonday: 'S',
      firstLetterTuesday: 'T',
      firstLetterWednesday: 'Q',
      firstLetterThursday: 'Q',
      firstLetterFriday: 'S',
      firstLetterSaturday: 'S',
    },
    waitTime : "Tempo de espera",
    serviceHour: "Horário de atendimento",
    blockingHours: "Horário de bloqueio",
    lunchHour: "Horário de almoço",
    until: "até",
    averageServiceTime: "Tempo de atendimento médio (minutos)",
    serviceDays: "Dias de Atendimento",
    blockingDays: "Dias de Bloqueio",
    blockingWeekDays: "Dias de Bloqueio (na semana)",
    medicalAgreement: "Convênio",
    birthday: "Data de Nascimento",
    gender: "Gênero",
    rg: "RG",
    civilStatus: "Estado Civil",
    profession: "Profissão",
    religion: "Religião",
    cardNumber: "Nº da Carteirinha",
    zipCode: "CEP",
    neighborhood: "Bairro",
    select: "Selecione",
    colorTag: 'Cor da etiqueta para atendimento: ',
    colorCategory: "Cor da etiqueta para categoria: ",
    standardTime: 'Tempo padrão em minutos para o atendimento',
    standardValue: 'Valor (R$) padrão para o atendimento',
    dropZoneTitle: 'Arraste e solte para fazer upload!',
    dropZoneSubTitle: '...ou clique para selecionar um arquivo do computador',
    error: {
      invalidHour: "Você digitou um horário inválido",
      hourDiff: "Horário final do período não pode ser menor que inicial.",
      hour: "Hora inválida",
      accessRequired: "Você não tem o acesso requerido para esta ação",
      requiredShort: "Obrigatório",
      required: "Esse campo é requerido",
      minLength: "O texto é muito curto",
      maxLength: "O texto é muito longo",
      update: "Houve algum erro",
      email: "Email inválido",
      conflictEmail: "Este email já está em uso para outro usuário",
      image: {
        type: "Tipo de arquivo inválido, esperado: PNG, JPEG",
        upload: "Erro ao fazer ulpload da imagem, tente novamente mais tarde!"
      },
      pdf: {
        type: "Tipo de arquivo inválido, esperado: PDF",
        upload: "Erro ao fazer ulpload do PDF, tente novamente mais tarde!"
      },
      invalidCPF: "Cpf inválido",
      formInvalid: "Você precisa preencher os seguintes campos obrigatórios: ",
      cpfError: " Cpf;",
      emailError: " Email;",
      nameError: " Nome;",
      phoneError: " Telefone;",
      crmError: " CRM;",
      specialtyError: " Especialidade;",
      passwordError: " Senha;",
      permissionError: " Permissões;",
      schedulesError: " Agendas;",
      cnpjError: " CNPJ;",
      corporateNameError: " Nome da clínica;",
      companyNameError: " Razão social da clínica;",
      slugError: " Palavras Chaves;",
      idError: " Id;",
      categoryError: " Categoria;",
      titleError: " Titulo;",
      fontError: "Fonte",
      abstractError: " Resumo;",
      clinicsError: " Clínicas;",
      videosError: " Vídeos;",
      uploadImage: "Erro ao fazer upload de imagem",
      closeEditor: "Conclua ou cancele o editor antes de proseguir",
      showOnMenu: "Mostrar no menu",
      regionAreaName: "Erro ao obter nome da área da região",
      schedule: "Erro a reduzir número de escalas",
      tagType: "A tag deve ser aplicável a produtos e ou empresas",
      onUpdate: "Espere a conclusão da operação anterior",
      chosePermissionAndSchedules: "Escolha as permissões e as agendas",
    },
    update: "Atualizar",
    companyName: "Nome da clínica",
    corporateName: "Razão social",
    updateSuccess: "Dados salvos",
    ownerName: "Nome do proprietário",
    email: "Email",
    password: "Senha",
    continue: "Continuar",
    cnpj: "CNPJ",
    cpf: "CPF",
    name: "Nome",
    prevStep: "Etapa Anterior",
    nextStep: "Próxima etapa",
    conclude: "Salvar",
    close: "Fechar",
    cancel: "Cancelar",
    changePrimaryColor: "Alterar cor primária",
    changeSecondaryColor: "Alterar cor secundária",
    emailResetSuccess: "Em instantes você receberá um email para redefinir a sua senha",
    emailResetError: "Houve um erro ao tentar enviar email de redefinição de senha",
    changePassword: "Alterar Senha",
    passwordNew: "Nova Senha",
    passwordOld: "Senha atual",
    info: "Informação do clínica",
    address: {
      city: "Cidade",
      complement: "Complemento",
      country: "País",
      district: "Bairro",
      neighborhood: "Bairro",
      number: "Número",
      search: "Buscar endereço",
      state: "Estado",
      street: "Rua",
      emptyResult: "Nenhum endereço encontrado",
      fieldWarning: "Digite o endereço corretamente",
      zipcode: "CEP",
    },
    contact: "Contato",
    description: "Descrição",
    phone: "Telefone",
    phone1: "Telefone 1",
    phone2: "Telefone 2",
    delete: "Deletar",
    edit: "Editar",
    add: "Adicionar",
    permission: "Permissão",
    active: "Ativo",
    create: "Criar",
    isLiquid: "É líquido",
    isMandatory: "Obrigatório",
    numberOfChoices: "Máximo de escolhas (0 sem limite)",
    print: "Imprimir",
    showIds: "Mostrar Ids",
    visible: "Visísvel",
    required: "Obrigatório",
    nutritionalUnit: "Unidade de medida",
    showOnMenu: "Mostrar no menu",
    isCash: "É dinheiro?",
    getDeliveryAreaName: "Nome da área de entrega",
    getRegionName: "Nome da área da região",
    isProduct: "Aplicável em produtos",
    isCompany: "Aplicável em empresas",
    isDoctor: "Médico",
    isPhysiotherapist: "Fiosterapeuta",
    isNutritionist: "Nutricionista",
    isPsychologist: "Psicólogo",
    isPersonal: "Personal",
    isDentist: "Dentista",
    isTherapist: "Terapeuta",
    isCaretaker: "Cuidador",
    doctorDocument: "CRM",
    nutritionistDocument: "CRN",
    psychologistDocument: "CRP",
    physiotherapistDocument: "CREFITO",
    personalDocument: "CONFEF",
    dentistDocument: "CONFEF",
    therapistDocument: "CRT",
    caretakerDocument: "",
    CRM: "CRM",
    CRN: "CRN",
    CRP: "CRP",
    CREFITO: "CREFITO",
    CONFEF: "CONFEF",
    CFO: "CFO",
    CRT: "CRT",
    placeholderInsurances: "Convênios Aceitos",
    specialty: "Especialidade ",
    addressDoctor: "Endereço de atendimento",
    category: "Categoria",
    title: "Título",
    slug: "Palavras chaves para url",
    abstract: "Resumo",
    font: "Fonte",
    bodyText: "Conteúdo",
    emptyResult: "Nenhum resultado",
    lang: {
      ptbr: "Br",
      en: "EN"
    },
    isBookmarkable: "Pode ser adicionado aos favoritos",
    confirmQuestion: "Você tem certeza que deseja efetuar essa ação?",
    confirmDeleteFile: "Você tem certeza que deseja Remover esse arquivo?",
    confirmQuestionYes: "Sim",
    confirmQuestionCancel: "Cancelar",
    search: {
      emptyResult: "Nenhum resultado encontrado"
    },
    placeholderSpecialities: "Selecione as especialidades",
    approve: "Aprovar",
    reject: "Rejeitar",
    doctors: "Médicos",
    remove: "Excluir",
    videoUpload: "Upload de vídeo"
  },
  register: {
    increaseSails: "",
    discoverAdvantages: "",
    signIn: "Cadastre sua clínica!",
  ifAccountExist: "Se já possui uma conta, faça login",
    orSignIn: "ou crie uma nova conta para começar."
  },
  navBar: {
    signIn: "Cadastre-se",
    logIn: "Entrar",
    logOut: "Sair",
    orSignIn: "Ou cadastre-se",
    singIn: "Torne-se um parceiro",
    start: "Começar",
  },
  image: {
    crop: "Crop",
    zoomIn: "Zoom In",
    zoomOut: "Zoom Out",
    rotateLeft: "Girar Esquerda",
    rotateRight: "Girar Direita",
    mirrorY: "Espelhar Y",
    mirrorX: "Espelhar X"
  },
  profile: {
    title: {
      client: "Informações",
      admin: "Informações",
      company: "Informações do Clínica",
      user: "Informações do usuário"
    },
    doctorRequest: {
      title: "Estado da solicitação para ser Médico: ",
      none: "Ainda não solicitado, preencha as suas informações para submeter o seu cadastro para análise",
      approved: "Aprovado",
      rejected: "Rejeitado",
      pending: "Pendente",
    },
    profileRequest: {
      title: "Estado da solicitação para ser receber exames: ",
      none: "Ainda não solicitado, adicione uma foto que mostre o seu rosto e um documento",
      approved: "Aprovado",
      rejected: "Rejeitado",
      pending: "Pendente",
    },
    youAreSure: "Rejeitar?",
    youAreWantCancelOrder: "",
    youAreYes: "Sim",
    youAreNo: "Não",
    cancelReason: "Motivo da rejeição"
  },
  promotion: {
    step: "Etapa",
    of: "de",
    createPromotion: "Criar promoção",
    editPromotion: "Editar promoção",
    infoSub: "Informações da promoção",
    line1: "Linha1",
    line2: "Linha2",
    description: "Descrição da promoção",
    behaviorSub: "Funcionamento da promoção",
    behaviorAll: "Aplicar a todos os pedidos",
    behaviorAllDesc: "Aplicar promoção a todos os pedidos de qualquer cliente.",
    behaviorFirst: "Aplicar apenas ao primeiro pedido do cliente",
    behaviorFirstDesc: "Aplicar promoção apenas ao primeiro pedido de um novo cliente.",
    behaviorCoupon: "Aplicar com código promocional",
    behaviorCouponDesc: "Aplicar promoção por meio de código promocional.",
    typeSub: "Tipo de promoção",
    typeDelivery: "Delivery grátis",
    typeDeliveryDesc: "Isenção da taxa de entrega dos pedidos via delivey.",
    typePercent: "Porcentagem de desconto",
    typePercentDesc: "Aplique um percentual de desconto sobre o valor dos produtos.",
    typeValue: "Valor de desconto",
    typeValueDesc: "Desconte um valor determinado ao final do pedido.",
    typeGift: "Brinde",
    typeGiftDesc: "Inclua um ou mais produtos gratuitamente ao pedido.",
    applyInSub: "Abrangência da promoção",
    applyInAll: "Cardápio completo",
    applyInAllDesc: "A promoção se aplicará a todos os itens do seu cardápio.",
    applyInCategories: "Categorias selecionadas",
    applyInCategoriesDesc: "Selecione uma ou mais categorias do cardápio para aplicar a promoção.",
    applyInProducts: "Produtos selecionados",
    applyInProductsDesc: "Selecione produtos específicos aos quais a promoção se aplica.",
    configPromotion: "Configurar promoção",
    durationSub: "Duração e validade",
    isValidity: "Duração determinada",
    isValidityDesc: "Determine uma data para encerrar a promoção automaticamente.",
    isRecurrent: "Promoção recorrente",
    isRecurrentDesc: "Programe a promoção para que se repita automaticamente.",
    restrictionsSub: "Restrições da promoção",
    isQuantity: "Limitar quantidade",
    isQuantityDesc: "Determine uma quantidade de pedidos para encerrar a promoção.",
    isTakeOut: "Tirar",
    isTakeOutDesc: "A promoção se aplica aos produtos retirados na loja.",
    isDelivery: "Delivery",
    isDeliveryDesc: "A promoção se aplica aos produtos entregues via delivery.",
    isMinValue: "Valor mínimo",
    isMinValueDesc: "A promoção se aplica aos pedidos com um valor mínimo.",
    detailsPromotion: "Detalhes da promoção",
    discountValueSub: "Valor do desconto",
    discountPercent: "Porcentagem de desconto",
    discountValue: "Valor de desconto",
    recurrentNot: "Não repetir a promoção",
    recurrentNotDesc: "A promoção não será repetida",
    recurrentSub: "Recorrência da promoção",
    recurrentWeekly: "Semanalmente",
    recurrentWeeklyDesc: "A promoção será repetida no mesmo horário uma vez por semana",
    recurrentDaily: "Diariamente",
    recurrentDailyDesc: "A promoção será repetida todos os dias no mesmo horário",
    recurrentMonthly: "Mensalmente",
    recurrentMonthlyDesc: "A promoção será repetida no mesmo dia e horário todos os meses",
    limitQuantity: "Limite de pedidos",
    minValue: "Valor Mínimo",
    dateBegin: "Data de início",
    dateEnd: "Data de término",
    hourBegin: "Hora de início",
    hourEnd: "Hora de termino",
    recurrentWeeklySub: "Selecione os dias da semana",
    recurrentMonthlySub: "Selecione o dia do mês",
    duration: "Duração da promoção"
  },
  calendar: {
    week: {
      "0": "Domingo",
      "1": "Segunda",
      "2": "Terça",
      "3": "Quarta",
      "4": "Quinta",
      "5": "Sexta",
      "6": "Sábado"
    },
    dayWeek: "Dia da semana",
    enableDisable: "Habilitar/Desabilitar dia",
    enable: "Habilitar",
    pattern: "Padrão",
    from: "Das",
    at: "às",
    andFrom: "das",
    split: "Dividir",
    continuous: "Contínuo",
    saveChanges: "Salvar Alterações",
    newEvent: "Novo evento",
    event: "Evento",
    dateStart: "Data Início",
    dateEnd: "Data Fim",
    hourStart: "Início",
    hourEnd: "Fim",
    services: "Serviços",
    totalPrice: "Preço Total",
    saveEvent: "Salvar Evento",
    deleteEvent: "Apagar Evento",
    title: "Título",
    block: "Bloqueio",
    toBlock: "Bloquear",
  },
  access: {
    title: "Acesso",
    admin: "Administrador",
    editor: "Editor",
    masterEditor: "Master Editor",
    attendant: "Atendente",
    delivery: "Entregador",
    doctor: "Médico",
  },
  tools: {
    title: "Ferramenta"
  },
  deliveryOptions: {
    deliveryConfig: "Configuração de entrega",
    deliveryErrorLatlng: "Configure o endereço da empresa primeiro",
    kmValue: "Valor da entrega por km",
    minValue: "Valor mínimo",
    roundAt: "Arredondar a cada"
  },
  otherOptions: {
    acceptDelivery: " Permite pedidos para entrega",
    acceptDeliveryDesc: "Aceita entregas",
    acceptPaymentOutApp: "Aceita pagamento fora do app",
    acceptPaymentOutAppDesc: "Aceita pagamento fora do App",
    acceptSails: "Aceita vendas no app",
    acceptSailsDesc: "Aceita vendas",
    acceptTakeAtEstablishment: "Permite pedidos a retirar no estabelecimento",
    acceptTakeAtEstablishmentDesc: "Aceita Takeout",
    otherOptionsSub: "Outras configurações",
    showOnlyCategoryOnMenu: "Mostrar apenas Subcategorias no Menu",
    showOnlyCategoryOnMenuDesc: "Menu de um nível",
    acceptTakeout: "Aceita Retirada",
    acceptOnSite: "Aceita retirar na loja",
    timeForDelivery: "Tempo médio de delivery",
    timeForDeliveryMin: "De",
    timeForDeliveryMax: "Até",
    timeForPickupAndOnSite: "Tempo médio de retirada",
    timeForPickupAndOnSiteMin: "De",
    timeForPickupAndOnSiteMax: "Até"
  },
  product: {
    nutritionalName: "Nome",
    nutritionalValue: "Valor",
    nutritionalPortion: "Porção",
    nutritionalValuePortion: "Valor Porção",
    nutritionalValueVd: "VD %",
    vdDaily: "VD Diário",
    description: "Descrição",
    name: "Nome",
    nutritional: "Nutricional",
    portion: "Porção",
    value: "Preço",
    placeholderTags: "Escolha as Tags",
    placeholderNutritional: "Escolha os itens Nutricionais",
    placeholderIngredients: "Escolha os ingredientes",
    notVisible: "Não visível",
    category: "Categoria",
    variantName: "Variação",
    variantValue: ""
  },
  multiSelect: {
    selectLabel: "Pressione enter para selecionar",
    selectGroupLabel: "Pressione enter para selecionar o grupo",
    selectedLabel: "Selecionado",
    deselectLabel: "Pressione enter para remover",
    deselectGroupLabel: "Pressione enter para cancelar a seleção do grupo",
    placeholder: "Selecione a opção",
    selectPermission: "Selecione as permissões",
    selectSchedules: "Selecione as agendas que este usuário terá acesso",
    selectVideos: "Selecione os vídeos",
  },
  additional: {
    additionals: "Adicionais",
    editAdditional: "Editar Adicional",
    freeText: "Opcionais gratuitos",
    itemTitle: "Nome da opção",
    itemValue: "Valor da opção",
    placeholder: "Categorias Aplicáveis",
    title: "Adicional",
    type: "Tipo",
    variation: "Variantes",
    inputTitle: "Título",
    flavor: "Múltiplos sabores",
    allVariations: "Todas as variações"
  },
  listItems: {
    noGroup: "Produto sem categoria"
  },
  orderStatus: {
    canceled: "Pedido Cancelado",
    confirmed: "Pedido Confirmado",
    deliveryReady: "Entrega Concluída",
    takeoutReady: "Pedido Concluído",
    inPreparation: "Em Preparação",
    makingOrder: "Montando Pedido",
    waitDelivery: "Aguardando Entrega",
    onDelivery: "Em Entrega",
    waitConfirm: "Aguardando Confirmação",
    waitPayment: "Aguardando Pagamento",
    waitTakeout: "Aguardando Retirada"
  },
  orderType: {
    takeout: "Retirada",
    undefined: "Não definido"
  },
  paymentStatus: {
    confirm: "Pagamento Confirmado",
    undefined: "Pagamento não definido",
    invalidPaymentMethod: "Método de pagamento Inválido",
    invalidPaymentCard: "Erro com o cartão",
    invalidPaymentUser: "Usuário inválido",
    invalidPaymentUnknown: "Erro de pagamento desconhecido",
    invalidPaymentAlreadyEarly: "Pagamento já realizado",
    invalidPaymentLimitValue: "Desculpe, mas temos um limite de R$ 500,00 por compra no cartão.",

    iuguNotApproved: "Transação negada",
    iuguResponseUnknown: 'Erro ao processar o pagamento',
    iuguPaymentError: "Erro ao processar o pagamento!",
    iuguPaymentErrorCatch: "Erro ao processar o pagamento!!",
    iuguRefundErrorCatch: 'Erro ao estornar pagamento',
    iuguRefundErrorResult: 'Erro ao estornar pagamento!',

    iuguPending: "Pendente",
    iuguPaid: "Paga",
    iuguCanceled: "Cancelada",
    iuguDraft: "Rascunho",
    iuguPartiallyPaid: "Parcialmente Paga",
    iuguRefunded: "Reembolsada",
    iuguExpired: "Expirada",
    iuguInProtest: "Em Protesto",
    iuguChargeback: "Contestada",
    iuguInAnalysis: "Em Análise",
    iuguUndefined: "Indefinido",
  },
  paymentOptions: {
    bankCheck: "Pagamento em Cheque",
    creditCard: "Pagamento em Cartão de Crédito",
    debitCard: "Pagamento em Cartão de Débito",
    money: "Pagamento em Dinheiro",
    paymentOptionsSub: "Opções de pagamento",
    acceptPaymentOnApp: "Aceita pagamento no App",
    acceptCashPayment: "Aceita dinheiro",
    acceptPaymentOnDelivery: "Aceita pagamento na entrega",
    placeholderCards: "Adicione os cartões aceitos",
    auto_withdraw: "Saque automático",
    auto_advance: "Antecipação Automática.",
    iuguInfo: "Informações da conta de pagamento",
    createIuguVerify: "Enviar dados para validação",
    alertNotAvailableForIugu: "Essa empresa não foi habilitada para criar conta de pagamento",
    createIugu: "Criar conta de pagamento",
    notAvailableForIugu: "Essa empresa não foi habilitada para criar conta de pagamento, entre em contato com o suporte.",
    createIuguVerifyAccept: "Conta Aceita",
    createIuguVerifyBank: "Verificar Banco",
    infoTitle: "Informações",
    createIuguTitle: "Criar Conta de recebimento",
    formTitle: "Formulário",
    bankTitle: "Criar ou atualizar banco",
    createIuguPrevious: "Conta já criada",
    needCreateIugu: "É necessário criar suconta de recebimento primeiro",
    receiveInSubAccount: "Alterar para recebimento em subconta",
    receiveInMasterAccount: "Alterar para recebimento através do Go Tap"
  },
  iugu: {
    name: "Nome da empresa",
    is_verified: "Verificado?",
    can_receive: "Pode receber?",
    last_verification_request_status: "Estado da última verificação",
    last_verification_request_data: "Dados da última verificação",
    last_verification_request_feedback: "Feedback da última verificação",
    change_plan_type: "Plano",
    balance: "Saldo",
    balance_in_protest: "Saldo em protesto",
    balance_available_for_withdraw: "Saldo disponível para entrega",
    protected_balance: "Saldo protected",
    payable_balance: "Saldo a pagar",
    receivable_balance: "Saldo a receber",
    commission_balance: "Saldo de comissão",
    volume_last_month: "Último mês",
    volume_this_month: "Esse mês",
    taxes_paid_last_month: "Taxas pagas no último mês",
    taxes_paid_this_month: "Taxas pagas esse mês",
    has_bank_address: "Tem endereço bancário",
    commissions_percent: "Taxa Go Tap",
    configuration: {
      auto_withdraw: "Saque automático"
    },
    accountVerification: {
      title: "Verificação de conta",
      price_range: "Valor máximo da venda ('Até R$ 100,00', 'Entre R$ 100,00 e R$ 500,00', 'Mais que R$ 500,00')",
      physical_products: "Produtos físicos?",
      business_type: "Descreva o seu negócio",
      person_type: "Tipo de cadastro",
      person_typeFisica: "Pessoa Física",
      person_typeJuridica: "Pessoa Jurídica",
      automatic_transfer: "Transferência automática(recomendado sim)",
      name: "Nome",
      company_name: "Nome da empresa",
      cpf: "CPF",
      cnpj: "CNPJ",
      resp_name: "Nome do responsável",
      resp_cpf: "CPF do responsável",
      telephone: "Telefone",
      account_type: "Tipo de conta",
      bank: "Banco",
      account_typeCorrente: "Corrente",
      account_typePoupanca: "Poupança",
      bank_ag: "Agência (9999-9)",
      bank_cc: "Conta (999999-9)",
      document: "Comprovante bancário(O comprovante de domicílio bancário é algum documento que contenha as informações da conta bancária (Nome do Banco, Agência, Conta) bem como o nome da empresa ou CNPJ para Pessoa Jurídica)"
    }
  },
  companyInfo: {
    title: "Informações da empresa",
    das: "Das",
    as: "Às",
    isClose: "Fechado",
    attendanceTitle: "Horários de atendimento",
    paymentTitle: "Formas de pagamento aceitas"
  },
  timeElapsed: {
    "12": "12 horas",
    "24": "24 horas",
    "48": "2 dias",
    "120": "5 dias",
    "240": "10 dias",
    "720": "30 dias",
    label: "Período dos pedidos"
  },
  notifications: {
    examTitle: "O seu exame está disponível",
  },
  rules: {
    adm: {
      adm: "Administrador",
      sell: "Vendedor",
    },
    comp: {
      adm: "Administrador",
      edit: "Editor",
      mEdit: "Master Editor",
      deli: "Delivery",
      att: "Atendente",
      doc: "Médico",
    }
  },
  router: {
    accessDenied: "Acesso Negado"
  },
  orderError: {
    statusError: "A bag está em um estado inválido para ser transformada em order",
    permissionDenied: "O usuário não tem permissão para alterar essa bag",
    unknown: "Erro desconhecido",
    paymentError: "Erro ao efetuar o pagamento",
    bagError: "Há algum erro com a bag"
  },
  maps: {
    areaDeliveryInfo: "Área de entrega"
  },
  prescription: {
    searchMedicament: "Medicamento",
    dosage: "Posologia",
    selectPatient: "Selecione o paciente",
    pdf: {
      medicament: "Medicamento: ",
      dosage: "Posologia: ",
      CRM: "CRM: "
    },
    patient: "Paciente",
    doctor: "Médico"
  },
  exam: {
    addItem: "Adicionar item",
    typeLst: {
      text: "Texto",
      select: "Seleção",
      checkbox: "Opções",
      number: "Número"
    },
    type: "Tipo de valor",
    referenceType: "Tipo de valor de referência",
    referenceText: "Texto de referência",
    referenceMin: "Acima de ",
    referenceMax: "Abaixo de",
    options: "Opções",
    addOption: "Adicionar opção",
    optionName: "Nome da opção",
    unit: "Unidade de medida",
    type2: "Tipo de valor(2)",
    referenceType2: "Tipo de valor de referência(2)",
    referenceText2: "Texto de referência(2)",
    referenceMin2: "Acima de (2)",
    referenceMax2: "Abaixo de (2)",
    options2: "Opções(2)",
    addOption2: "Adicionar opção(2)",
    optionName2: "Nome da opção(2)",
    unit2: "Unidade de medida(2)",
    unitLst: {
      none: "Sem unidade",
      ml: "ml",
      milhoesmm3: "milhões/mm³",
      percent: "%",
      gDL: "g/dL",
      fL: "fL"
    },
    addSection: "Adicionar seção",
    nameOfSection: "Nome da seção",
    section: "Seção",
    has2values: "Tem valor secundário?",
    isEvaluated: "Já foi analisado?",
    template: "Template",
    addHealthDataFields: "Adicionar tipo de dado de saúde",
    selectDoctors: "Selecione os médicos",
    selectPatient: "Selecione o paciente",
    date: "Data do exame",
    fileType: {
      pdf: "PDF",
      image: "Imagem",
      pdfImage: "PDF e Imagem"
    },
    filePdf: "Adicione PDF",
    fileImage: "Adicione imagens",
    selectCompany: "Clínica",
    removed: "Esse exame não existe mais",
    patient: "Paciente",
    evaluatedYes: "Exame desmembrado",
    evaluatedNot: "Exame não desmembrado",
    company: "Clínica"
  },
  blog: {
    title: "",
    notFound: "Conteúdo não encontrado",
    isNotPostVideo: "Não foram adicionadas fotos e nem vídeos",
    font: "Fonte: ",
  },
  chat: {
    newChat: "Nova conversa",
    writeSomething: "Diga algo",
    dateFormat: "DD MMMM YYYY",
    hourFormat: "HH:mm",
    notification: {
      title: 'Nova mensagem recebida',
      body: ''
    }
  },
  home: {
    resources: "Recursos",
    solution: "Uma solução completa para o consultas à distância",
    yourData: "Encontre profissionais à sua disposição",
    downloadApp: "Baixe e instale o aplicativo em seu celular para se consultar com o seu médico sem sair de casa",
    exam: "Faça seus exames com mais praticidade",
    scheduleTitle: "Agenda de consultas",
    schedule: "Ao agendar consultas com clínicas que disponibilizam o serviço Mais Saúde, as datas e horários dos atendimento são atualizadas automaticamente no aplicativo.",
    contentTitle: "Conteúdo Exclusivo",
    content: "Na tela inicial do aplicativo você encontra conteúdos exclusivos do Canal Mais Saúde, com dicas e novidades de saúde e tecnologia\n" +
      "para se manter sempre atualizado.",
    waitRoomTitle: "Sala de Espera",
    waitRoom: "Pouco antes da sua consulta começar você tem acesso a uma sala de espera digital, com conteúdos interativos e vídeos personalizados da clínica, enquanto aguarda seu médico.",
    recordTitle: "Prontuário Digital",
    record: "Ao ser atendido, seu médico tem acesso a um prontuário digital completo, onde seus dados ficam organizados e podem ser consultados a cada atendimento.",
    paymentTitle: "Pagamentos Integrados",
    payment: "Além da integração com planos de saúde, os pagamentos particulares podem ser feitos de forma rápida e fácil pelo aplicativo, bastando aceitar a solicitação enviada pela clínica.",
    privacyTitle: "Privacidade e Segurança",
    privacy: "Todas as informações de pacientes e clínicas são armazenadas de forma segura, atendendo as exigências técnicas e garantindo a confidencialidade dos dados.",
    forDoctors: "Para médicos e clínicas",
    subscribe: "Inscreva-se agora e conheça todos os recursos profissionais que o Canal Mais Saúde oferece.",
    teleconsult: "Canal Mais Saúde - 2020",
  },
  emailReset: {
    subject: "Resetar senha",
    head: "Email de redefinição de senha",
    body: "",
    link: "Resetar senha"
  },
  emailFirstPassword : {
    subject: "Primeira senha de acesso",
    head: "Usuário: ",
    body: "Sua senha para o primeiro acesso é: ",
    email: "Email de acesso: ",
    link: "Primeiro acesso",
  },
  videoStatus: {
    UNKNOWN: "Pendente",
    COMPLETE: "Pronto",
  },
  dropzoneFCM: {
    title: 'Solte os arquivos aqui ou clique para fazer o upload.',
    info: '',
  },
  uploadStatus: {
    COMPLETE: 'Pronto',
    UNKNOWN: 'Visualização não disponível',
    PENDING: 'Processando',
  },

  placeholder: {
    similarWords: "Palavras que serão usadas pelo usuário para busca",
    rqe: "RQE",
    facebook: "Link do facebook",
    instagram: "Link do instagram",
    youtube: "Link do canal",
    site: "Digite o link do seu site",
    name: "Nome",
    nameCompany: "Nome da clínica",
    nameCorporate: "Razão social da clínica",
    namePacient: "Nome do(a) paciente",
    nameUser: "Nome do usuário",
    cpf: "000.000.000-00",
    cnpj: "00.000.000/0000-00",
    email: "email@dominio.com",
    minimumCaracters: "No mínimo 6 caracteres",
    birthDate: "00/00/0000",
    gender: "M: Masculino, F: Feminino",
    cell: "(00) 00000-0000",
    addressPacient: "Nome da rua, número",
    descriptionCompany: "Uma breve descrição da clínica",
    newPassword: "Nova senha do usuário: ",
    alterPassword: "Alterar senha do usuário: ",
    clinicSituation: "Situação da clínica: ",
    active: "Ativa",
    desative: "Desativada",
    medicalAgreement: "Nome do convênio",
    nameTypeCare: "Nome do tipo de atendimento",
    timeCare: "00 minutos",
    slug: "Palavras chaves, importantes para url",
    titleBlog: "Titulo do artigo/blog",
    abstractBlog: "Breve resumo",
    fontBlog: "De onde vem este conteúdo",
    titleVideo: "Digite o título do vídeo",
    category: "Selecione uma categoria",
    tags: "Tags",
    idVideo: "Digite o id do vídeo pego no youtube",
    nameDoctor: "Digite o nome do médico",
    nameCategory: "Digite o nome da categoria",
    specialty: "Digite a especialidade ",
    crefito: "",
    crm: "0000/MS",
    crn: "",
    crp: "",
    confef: "",
    cfo: "",
    crt: "",
    permission: "Selecione as permissões",
    schedules: "Selecione as agendas que este usuário terá acesso",
    nameVideo: "Digite o nome do vídeo",
    namePlaylist: "Digite o nome da playlist",
    choseClinics: "Escolha as clínicas",
    choseDoctors: "Escolha os médicos",
    choseVideos: "Escolha os vídeos que farão parte da playlist",
  },
  obs: {
    permissionDoctor: "Obs: Este usuário precisa da permissão de médico.",
    requiredFields: "Obs: Campos com (*) são obrigatórios.",
  },
  validateActive: {
    msg: 'Para ativar uma clínica você precisa de: ',
    doctor:'Pelo menos um médico cadastrado; ',
    playlist: 'Pelo menos uma playlist cadastrada e ativa; ',
    schedules:  'Pelo menos uma agenda cadastrada; ',
    active: "Clínica Ativada",
    desactive: "Clínica Desativada",
  },
  tele: {
    offerConnection: "Iniciar Chamada",
    answerConnection: "Aceitar Chamada",
    waitingConnection: "Esperando Chamada",
    errorWaitConnection: "Espere o paciente iniciar a chamada",
    hangUp: "Encerrar Chamada",
    errorNavigator: "Não foi possível obter imagem, seu navegador está atualizado?",
    errorHttp: "Utilize https para acessar esse recurso",
    errorStartingCam: "Erro ao iniciar a câmera",
    errorStartingScreen: "Erro ao iniciar o compatilhamento de tela",
    errorStartRemoteVideo: "Erro ao iniciar componete de video remoto",
    errorStartLocalVideo: "Erro ao iniciar componete de video local",
    errorOnRecord: "Erro ao iniciar Gravação",
    errorUnknown: "Erro desconhecido",
    errorTypeRecord: "Não foi encontrado um tipo de mídia compatível no seu navegador para efetuar a gravação",
    warnOnRecordType: "Não foi encontrado um tipo de mídia compatível no seu navegador para efetuar a gravação, vamos tentar gravar em webm",
    warnChangeTypeOnRecord: "Essa mudança fará efeito na próxima gravação",
    warnNoTrackToRecord: "Nenhuma mídia disponível para gravação",
    selectVideo: "Selecione o vídeo",
    selectInputAudio: "Selecione o microfone",
    selectOutputAudio: "Selecione o autofalante",
    selectRecordType: "Selecione o tipo de gravação",
    startSharedScreen: "Compartilhar de tela",
    stopSharedScreen: "Parar compartilhar de tela",
    configHeader: "Configurações",
    configVideo: "Câmera",
    configAudio: "Áudio",
    configRecord: "Gravação",
    config: "Config",
    changeMicrophoneOn: "Mute",
    changeMicrophoneOff: "Unmute",
    changeVideoOn: "Parar Vídeo",
    changeVideoOff: "Iniciar Vídeo",
    changeShareScreenOn: "Parar tela",
    changeShareScreenOff: "Compartilhar tela",
    changeRecordOn: "Parar gravação",
    changeRecordOff: "Iniciar gravação",
    connectionState: {
      new: 'Inicializando...',
      connecting: 'Conectando...',
      connected: 'Conectado',
      disconnected: 'Desconectado',
      failed: 'Falha na conexão',
      closed: 'Conexão encerrada',
    }
  }
};
