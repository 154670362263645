"use strict";

let toExport = {};

let links = {
  site: {
    name: 'Site',
    link: 'https://canalmaissaude.com.br/'
  },
  facebook: {
    name: 'Facebook',
    link: 'https://www.facebook.com/canalmaissaudeoficial'
  },
  instagram: {
    name: 'Instagram',
    link: 'https://www.instagram.com/canalmaissaudeoficial/'
  },
  youtube: {
    name: 'You Tube',
    link: 'https://www.youtube.com/channel/UC2jjik9WFg6Hw_H1sQCBBuA'
  },
  whatsapp: {
    name: 'Whatsapp',
    link: 'https://api.whatsapp.com/send?phone=5567999593337'
  },
};

const android = {
  linkStore: 'https://play.google.com/store/apps/details?id=br.com.justworks.maissaude',
  storeCode: '',
  id: 'br.com.justworks.maissaude',
  uri: 'vnd.mais_saude://',
  name: 'Mais Saúde',
  packageName: 'br.com.justworks.maissaude'
};

const ios = {
  linkStore: 'https://apps.apple.com/br/app/teleconsulta-mais-saude/id1545705132',
  storeCode: 'id1545705132',
  id: 'id1545705132',
  uri: 'vnd.mais_saude://',
  name: 'Mais Saúde',
  packageName: '',
};

toExport.listConfig = {
  'default': {
    name: 'Default ALGO DE ERRADO NÃO ESTÁ CERTO',
    firebase: null,
    fbId: '',

    title: 'JW Vue Boilerplate',
    description: 'JW Vue Boilerplate',
    downloadPlayStore: 'https://play.google.com/store/apps/details?id=br.com.justworks.maissaude',
    downloadAppleStore: 'https://apps.apple.com/br/app/teleconsulta-mais-saude/id1545705132',
    emailKey: 'maissaude-jw.web.app',
    baseUrl: 'https://maissaude-jw.web.app',
    links: links,
    ios: ios,
    android: android,
    rtc: null,
  },
  'production': {
    name: 'Mais Saude PRODUCTION',
    maps: {
      key: 'AIzaSyDzTBKDMGw8As6P4rniLUf8nOGuU53czVM'
    },
    title: 'Mais Saúde',
    description: 'Encontre profissionais à sua disposição',
    downloadPlayStore: 'https://play.google.com/store/apps/developer?id=JustWorks',
    downloadAppleStore: 'https://apps.apple.com/br/developer/alexandre-reis-corr%C3%AAa-cruz/id429125678',

    baseUrl: 'https://www.teleconsultamaissaude.com.br/',
    firebase: {
      apiKey: "AIzaSyCxyV9PjT8Xdj_X1b2LgnbucTCP5iVi8xo",
      authDomain: "maissaude-jw.firebaseapp.com",
      databaseURL: "https://maissaude-jw.firebaseio.com",
      projectId: "maissaude-jw",
      storageBucket: "maissaude-jw.appspot.com",
      messagingSenderId: "1034779293084",
      appId: "1:1034779293084:web:c7515dfe2d02815e080459",
      measurementId: "G-3648HV20G6"
    },
    fbId: '',
    emailKey: 'maissaude-jw.web.app',
    links: links,
    ios: ios,
    android: android,
    rtc: {
      iceServers: [
        {
          urls: [
            'stun:stun1.l.google.com:19302',
            'stun:stun2.l.google.com:19302',
          ],
        },
      ],
      iceCandidatePoolSize: 10,
    }
  },
  'developer': {
    name: 'Mais Saude - Developer',
    maps: {
      key: 'AIzaSyDzTBKDMGw8As6P4rniLUf8nOGuU53czVM'
    },
    title: 'Mais Saude DEV',
    description: 'Encontre profissionais à sua disposição',
    downloadPlayStore: 'https://play.google.com/store/apps/developer?id=JustWorks',
    downloadAppleStore: 'https://apps.apple.com/br/developer/alexandre-reis-corr%C3%AAa-cruz/id429125678',

    baseUrl: 'https://www.teleconsultamaissaude.com.br/',
    firebase: {
      apiKey: "AIzaSyCxyV9PjT8Xdj_X1b2LgnbucTCP5iVi8xo",
      authDomain: "maissaude-jw.firebaseapp.com",
      databaseURL: "https://maissaude-jw.firebaseio.com",
      projectId: "maissaude-jw",
      storageBucket: "maissaude-jw.appspot.com",
      messagingSenderId: "1034779293084",
      appId: "1:1034779293084:web:c7515dfe2d02815e080459",
      measurementId: "G-3648HV20G6"
    },
    fbId: '',
    emailKey: 'maissaude-jw.web.app',
    links: links,
    ios: ios,
    android: android,
    rtc: {
      iceServers: [
        {
          urls: [
            'stun:stun1.l.google.com:19302',
            'stun:stun2.l.google.com:19302',
          ],
        },
      ],
      iceCandidatePoolSize: 10,
    }
  }
};
toExport.config = toExport.listConfig['default'];

if (process.env.NODE_ENV === 'production' || process.env.VUE_APP_DEVELOPER_DATABASE_MODE === 'production') {
  toExport.config = toExport.listConfig['production'];
}
else {
  toExport.config = toExport.listConfig['developer'];
  console.log('Server config start configName: ' + toExport.config.name);
}

// console.log('Server config start configName: ' + toExport.config.name);

toExport.appConfig = toExport.config;

module.exports = toExport;
