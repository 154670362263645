// Conveniently import this file anywhere to use db
import {config} from '@src/config';
import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage' // Use lazy loading, on include common/FileUtils.js firebase/storage will be load
// import 'firebase/performance';
import 'firebase/analytics';


//
// <!-- Load the Firebase SDKs before loading this file -->
// <script src="/__/firebase/init.js"></script>
//
// fetch('/__/firebase/init.json').then(async response => {
//   firebase.initializeApp(await response.json());
// });


firebase.initializeApp(config.firebase);

// firebase.firestore().settings({cacheSizeBytes: 3e+8});
// firebase.firestore().enablePersistence({synchronizeTabs: true}).catch((err)=>{
//   console.error('Firestore, Erro ao habilitar Persistência, pode ser que tenha outra aba aberta do sistema', err);
// });



const firestore = firebase.firestore();
// Debug functions local http and onCall
if(config.configId==='emulator') {
  require("firebase/storage");
  firebase.app().functions().useEmulator('localhost', 5000);
  firebase.app().functions(config.region).useEmulator('localhost', 5000);
  // firebase.app().firestore().useEmulator('localhost', 8081);
  firestore.useEmulator('localhost', 8081);
  firebase.database().useEmulator("localhost", 9000);
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.storage().useEmulator("localhost", 9199);
}

const functions = firebase.app().functions(config.region);
if(process.env.NODE_ENV !== 'production') {
  window.jwDebug = window.jwDebug || {};
  window.firebaseDebug = firebase;
  // firebase.app().functions().useEmulator('localhost', 5000);
  // firebase.app().functions(config.region).useEmulator('localhost', 5000);
  // firebase.app().firestore().useEmulator('localhost', 8081);
  window.jwDebug.functions = functions;
}

firebase.auth().onIdTokenChanged((...args)=>{
  console.log('Debug: token refresh', args);
});

// firebase.firestore().enablePersistence({synchronizeTabs: true});

const { Timestamp, GeoPoint, FieldValue, FieldPath } = firebase.firestore;


const database = firebase.database();
const auth = firebase.auth()
export {Timestamp, GeoPoint, FieldValue, firestore, functions, firebase, FieldPath, database, auth};
