

export default {
  routeLogged: {
    ADMIN: 'admin/doctors',
    CLIENT: 'client/profile',
    COMPANY: 'company/playlists',
  },
  routeNotLogged: {
    ADMIN: 'admin/login',
    CLIENT: 'client/login',
    COMPANY: 'company/login',
  },
  routeLogOut: {
    ADMIN: 'admin/login',
    CLIENT: 'client/login',
    COMPANY: 'company/login',
  },
}
